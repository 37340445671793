import "./insuredPeopleInfo.css";
import TableDiffColor from "../../../common/table/tableDiffColor/TableDiffColor";
import { moneyComma } from "../../../../libs/libcollect";
import { useState } from "react";
import Step1 from "../../../insurproductinfo/Step1";
import Step2 from "../../../insurproductinfo/Step2";
import Step3 from "../../../insurproductinfo/Step3";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers/";
interface Props {
  conct_number: string;
  insurer_list: Array<any>;
  tot_prmim: string;
}
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "90%",
    zIndex: 100,
    padding: 0,
  },
};
const InsuredPeopleInfo = ({
  conct_number,
  insurer_list,
  tot_prmim,
}: Props) => {
  const goToInvoice = () => {
    window.open("/contract/invoice/" + conct_number, "_blank");
  };
  const { sl_prod_number } = useSelector(
    (state: RootState) => state.journeyRegister
  );

  const [insurModalIsOpen, setInsurModalIsOpen] = useState(false);
  const [btnStep, setBtnStep] = useState<number>(1);

  function closeModal() {
    setInsurModalIsOpen(false);
  }
  const listItem = () => {
    return insurer_list.map((item, idx: number) => (
      <tr key={idx}>
        <td>{idx + 1}</td>
        <td>{item.noinsd_name}</td>
        <td>{item.noinsd_eng_name}</td>
        <td>{item.noinsd_inttage}</td>
        <td>{item.noinsd_insurage}</td>
        <td>{moneyComma(item.individ_prmim)}원</td>
      </tr>
    ));
  };
  const showInsurance = () => {
    setInsurModalIsOpen(true);
  };
  return (
    <div>
      <div className="InsuredPeopleInfo">
        <TableDiffColor
          th={[
            "보험종류",
            "피보험자명",
            "영문명",
            "만나이",
            "보험나이",
            "보험료",
          ]}
          td={listItem()}
          align="center"
        />
      </div>
      <div className="InsuredPeopleInfo-btn">
        <button
          onClick={() => {
            setBtnStep(2);
            showInsurance();
          }}
        >
          보장내용보기
        </button>
        <button onClick={() => goToInvoice()}>인보이스 발행</button>
      </div>
      <div className="InsuredPeopleInfo-purchase-info">
        <h3>결제 정보</h3>
        <div>
          총 보험료 :{" "}
          <span className="InsuredPeopleInfo-purchase-total-price">
            {moneyComma(Number(tot_prmim))}원
          </span>
        </div>
      </div>
      <Modal
        isOpen={insurModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div>
          <div className="modal-exit">
            <button onClick={closeModal}>닫기</button>
          </div>
          <div className="insurance-content-container">
            <button
              style={{
                backgroundColor: btnStep === 1 ? "#444054" : "#F7F7F7",
                color: btnStep === 1 ? "#fff" : "#6c757d",
              }}
              className="insurance-content-container-btn"
              onClick={() => setBtnStep(1)}
            >
              상품설명
            </button>
            <button
              style={{
                backgroundColor: btnStep === 2 ? "#444054" : "#F7F7F7",
                color: btnStep === 2 ? "#fff" : "#6c757d",
              }}
              className="insurance-content-container-btn"
              onClick={() => setBtnStep(2)}
            >
              보장내용
            </button>
            <button
              style={{
                backgroundColor: btnStep === 3 ? "#444054" : "#F7F7F7",
                color: btnStep === 3 ? "#fff" : "#6c757d",
              }}
              className="insurance-content-container-btn"
              onClick={() => setBtnStep(3)}
            >
              알아두실사항
            </button>
          </div>
          <div className="insurance-content-container">
            {btnStep === 1 && <Step1 sl_prod_number={sl_prod_number} />}
            {btnStep === 2 && <Step2 sl_prod_number={sl_prod_number} />}
            {btnStep === 3 && <Step3 />}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InsuredPeopleInfo;
