import { useState } from "react";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";

interface Props {
  closeModal: any;
  sl_iprd_reg_if: string;
  total_prmim: number;
  total_prmim_cost: number;
}
const Register = ({
  closeModal,
  sl_iprd_reg_if,
  total_prmim,
  total_prmim_cost,
}: Props) => {
  const [step, setStep] = useState<number>(1);
  const nextStep = () => {
    setStep(step + 1);
  };

  switch (step) {
    case 1:
      return (
        <Step4
          closeModal={closeModal}
          nextStep={nextStep}
          sl_iprd_reg_if={sl_iprd_reg_if}
        />
      );
    case 2:
      return (
        <Step5
          closeModal={closeModal}
          nextStep={nextStep}
          sl_iprd_reg_if={sl_iprd_reg_if}
          total_prmim={total_prmim}
          total_prmim_cost={total_prmim_cost}
        />
      );
    case 3:
      return <Step6 nextStep={nextStep} closeModal={closeModal} />;
    case 4:
      return <Step7 />;
    default:
  }

  return <div></div>;
};

export default Register;
