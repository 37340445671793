import React from "react";
import { moneyComma } from "../../../libs/libcollect";
import "./constractDetailInfo.css";

interface Props {
  constractDetailInfo: {
    conct_number: string;
    ins_kind_name: string;
    iprd_name: string;
    insurcom_name: string;
    prod_name: string;
    plangrd_name: string;
    obj: string;
    member_sl_iprd_state_name: string;
    entryday: string;
    entry_cancel_day: string;
    ins_start_day: string;
    ins_end_day: string;
    tot_prmim: number;
  };
}

const ConstractDetailInfo = ({ constractDetailInfo }: Props) => {
  return (
    <section className="constract-inner">
      <h4 className="constract-title">■ 계약 상세 정보</h4>
      <table className="detail-info-table">
        <tr>
          <td className="detail-info-thread">계약번호</td>
          <td>{constractDetailInfo.conct_number}</td>
          <td className="detail-info-thread">보험종류</td>
          <td>{constractDetailInfo.ins_kind_name}</td>
          <td className="detail-info-thread">보험상품분류</td>
          <td>{constractDetailInfo.iprd_name}</td>
          <td className="detail-info-thread">보험사</td>
          <td>{constractDetailInfo.insurcom_name}</td>
        </tr>
        <tr>
          <td className="detail-info-thread">보험상품명</td>
          <td>{constractDetailInfo.prod_name}</td>
          <td className="detail-info-thread">플랜등급</td>
          <td>{constractDetailInfo.plangrd_name}</td>
          <td className="detail-info-thread">여행지</td>
          <td>{constractDetailInfo.obj}</td>
          <td className="detail-info-thread">보험상태</td>
          <td>{constractDetailInfo.member_sl_iprd_state_name}</td>
        </tr>
        <tr>
          <td className="detail-info-thread">가입일</td>
          <td>{constractDetailInfo.entryday}</td>
          <td className="detail-info-thread">가입취소일</td>
          <td>{constractDetailInfo.entry_cancel_day}</td>
          <td className="detail-info-thread">보험시작일</td>
          <td>{constractDetailInfo.ins_start_day}</td>
          <td className="detail-info-thread">보험종료일</td>
          <td>{constractDetailInfo.ins_end_day}</td>
        </tr>
        <tr>
          <td className="detail-info-thread">총 보험료</td>
          <td colSpan={7} style={{ textAlign: "left" }}>
            <div>{moneyComma(constractDetailInfo.tot_prmim)}</div>
          </td>
        </tr>
      </table>
    </section>
  );
};

export default ConstractDetailInfo;
