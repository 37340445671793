import React, { useState } from "react";
import { Input } from "antd";
import { useDispatch } from "react-redux";
import { changeTableDataRow } from "../../../redux/reducers/journeyRegisterReducer";
import { validRegistrationNumber } from "../../../libs/libcollect";

interface Props {
  key: any;
  data: any;
  deleteRow: any;
  areaGubun: string;
}
const TableRow = ({ key, data, deleteRow, areaGubun }: Props) => {
  const dispatch = useDispatch();
  const [rowData] = useState(data);

  const handleName = (
    key: string,
    idx: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (key === "kr") {
      dispatch(
        changeTableDataRow({
          idx: idx,
          regNumberFirst:
            data.regNumberFirst === undefined ? "" : data.regNumberFirst,
          regNumberLast:
            data.regNumberLast === undefined ? "" : data.regNumberLast,
          insurer_name: e.target.value.replace(" ", ""),
          insurer_mphone_number:
            data.insurer_mphone_number === undefined
              ? ""
              : data.insurer_mphone_number,
          insurer_eng_name:
            data.insurer_eng_name === undefined ? "" : data.insurer_eng_name,
        })
      );
    } else if (key === "eng") {
      dispatch(
        changeTableDataRow({
          idx: idx,
          regNumberFirst:
            data.regNumberFirst === undefined ? "" : data.regNumberFirst,
          regNumberLast:
            data.regNumberLast === undefined ? "" : data.regNumberLast,
          insurer_name:
            data.insurer_name === undefined ? "" : data.insurer_name,
          insurer_mphone_number:
            data.insurer_mphone_number === undefined
              ? ""
              : data.insurer_mphone_number,
          insurer_eng_name: e.target.value
            .toUpperCase()
            .replace(/[^A-Za-z]/gi, "")
            .replace(" ", ""),
        })
      );
    } else if (key === "regNumberFirst") {
      if (e.target.value) {
        e.target.value = e.target.value.replace(/[^0-9]/g, "");
        dispatch(
          changeTableDataRow({
            idx: idx,
            regNumberFirst: e.target.value,
            regNumberLast:
              data.regNumberLast === undefined ? "" : data.regNumberLast,
            insurer_name:
              data.insurer_name === undefined ? "" : data.insurer_name,
            insurer_mphone_number: data.insurer_mphone_number,
            insurer_eng_name: data.insurer_eng_name,
          })
        );
      } else {
        dispatch(
          changeTableDataRow({
            idx: idx,
            regNumberFirst: "",
            regNumberLast:
              data.regNumberLast === undefined ? "" : data.regNumberLast,
            insurer_name:
              data.insurer_name === undefined ? "" : data.insurer_name,
            insurer_mphone_number: data.insurer_mphone_number,
            insurer_eng_name: data.insurer_eng_name,
          })
        );
      }
    } else if (key === "regNumberLast") {
      if (e.target.value) {
        e.target.value = e.target.value.replace(/[^0-9]/g, "");

        if (e.target.value.length === 7) {
          if (
            !(
              validRegistrationNumber(
                data.regNumberFirst + e.target.value,
                "frn"
              ) ||
              validRegistrationNumber(
                data.regNumberFirst + e.target.value,
                "rrn"
              )
            )
          ) {
            window.alert("올바른 주민번호를 입력해주세요.");
            return;
          }
        }

        dispatch(
          changeTableDataRow({
            idx: idx,
            regNumberFirst:
              data.regNumberFirst === undefined ? "" : data.regNumberFirst,
            regNumberLast: e.target.value,
            insurer_name:
              data.insurer_name === undefined ? "" : data.insurer_name,
            insurer_mphone_number: data.insurer_mphone_number,
            insurer_eng_name: data.insurer_eng_name,
          })
        );
      } else {
        dispatch(
          changeTableDataRow({
            idx: idx,
            regNumberFirst:
              data.regNumberFirst === undefined ? "" : data.regNumberFirst,
            regNumberLast: "",
            insurer_name:
              data.insurer_name === undefined ? "" : data.insurer_name,
            insurer_mphone_number: data.insurer_mphone_number,
            insurer_eng_name: data.insurer_eng_name,
          })
        );
      }
    } else if (key === "insurer_mphone_number") {
      const phoneNumber = e.target.value;
      if (phoneNumber.length > 11) {
        alert("휴대폰번호가 유효하지 않습니다");
        return;
      }

      if (e.target.value) {
        dispatch(
          changeTableDataRow({
            idx: idx,
            regNumberFirst:
              data.regNumberFirst === undefined ? "" : data.regNumberFirst,
            regNumberLast:
              data.regNumberLast === undefined ? "" : data.regNumberLast,
            insurer_name:
              data.insurer_name === undefined ? "" : data.insurer_name,
            insurer_mphone_number: e.target.value.replace(/[^0-9]/g, ""),
            insurer_eng_name: data.insurer_eng_name,
          })
        );
      } else {
        dispatch(
          changeTableDataRow({
            idx: idx,
            regNumberFirst:
              data.regNumberFirst === undefined ? "" : data.regNumberFirst,
            regNumberLast:
              data.regNumberLast === undefined ? "" : data.regNumberLast,
            insurer_name:
              data.insurer_name === undefined ? "" : data.insurer_name,
            insurer_mphone_number: "",
            insurer_eng_name: data.insurer_eng_name,
          })
        );
      }
    }
  };
  return (
    <>
      <tr key={key}>
        <td>{rowData.idx}</td>
        <td
          style={{
            textAlign: "center",
          }}
        >
          <Input
            style={{
              width: "95%",
              border: "1px solid #CED4DA",
              borderRadius: 3,
            }}
            onChange={(e) => handleName("kr", data.idx, e)}
            value={data.insurer_name}
          />
        </td>
        {areaGubun === "IP20201202035154rjQR" && (
          <td style={{ textAlign: "center" }}>
            <Input
              style={{
                width: "95%",
                border: "1px solid #CED4DA",
                borderRadius: 3,
              }}
              onChange={(e) => handleName("eng", data.idx, e)}
              value={data.insurer_eng_name}
            />
          </td>
        )}
        <td style={{ textAlign: "center" }}>
          <Input
            style={{ width: "40%" }}
            onChange={(e) => handleName("regNumberFirst", data.idx, e)}
            value={data.regNumberFirst}
            maxLength={6}
          />{" "}
          -{" "}
          <Input
            style={{ width: "40%" }}
            onChange={(e) => handleName("regNumberLast", data.idx, e)}
            value={data.regNumberLast}
            maxLength={7}
          />
        </td>
        <td style={{ textAlign: "center" }}>
          <Input
            style={{ width: "95%" }}
            onChange={(e) => handleName("insurer_mphone_number", data.idx, e)}
            value={data.insurer_mphone_number}
          />
        </td>
        <td style={{ textAlign: "center" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {isNaN(data.age) ? 0 : data.age}
          </div>
        </td>
        <td style={{ textAlign: "center" }}>
          {isNaN(data.insu_age) ? 0 : data.insu_age}
        </td>
        <td style={{ width: "5%", textAlign: "center" }}>
          {data.idx === 1 ? (
            "대표가입자"
          ) : (
            <button
              style={{
                width: 50,
                height: 29,
                backgroundColor: "#6C757D",
                borderRadius: 3,
                color: "#fff",
              }}
              onClick={() => deleteRow(data.idx)}
            >
              삭제
            </button>
          )}
        </td>
      </tr>
    </>
  );
};
export default TableRow;
