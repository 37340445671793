import TableDiffColor from "../../common/table/tableDiffColor/TableDiffColor";
import "../../../pages/user/userMng/userMng.css";

interface Props {
  loginHistoryInfo: {
    regday: string;
    ip: string;
  }[];
}

const LoginHistoryInfo = ({ loginHistoryInfo }: Props) => {
  const listItem = () => {
    return loginHistoryInfo.map((item, index) => (
      <tr key={index}>
        <td>{item.regday}</td>
        <td>{item.ip}</td>
      </tr>
    ));
  };
  return (
    <section className="user-mng-table">
      <div className="user-mng-table-title">
        <h2>접속내역</h2>
      </div>
      <TableDiffColor th={["일시", "접속 IP"]} td={listItem()} align="center" />
    </section>
  );
};

export default LoginHistoryInfo;
