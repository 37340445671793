import React, { useEffect, useState } from "react";
import "../../style/basicInfo.css";
import InsuredPersonInfo from "../../common/InsuredPersonInfo";
import Warranty from "../../common/Warranty";
import Notice from "../../common/Notice";
import { reqPost } from "../../../../libs/requests";
import api from "../../../../libs/api";
import { useReactToPrint } from "react-to-print";
import BasicInfoTable from "../../common/BasicInfoTable";
import { moneyComma } from "../../../../libs/libcollect";
import Logo from "../../../../assets/images/toggle-logo-web-main.svg";
interface Props {
  paramId: string;
}

const BasicInfo = ({ paramId }: Props) => {
  const componentRef: any = React.useRef();
  const getDate = () => {
    let now = new Date();
    const getZero = (value: number) => {
      if (value < 10) {
        return `0${value}`;
      } else return value;
    };
    const year = now.getFullYear();
    const month = getZero(now.getMonth() + 1);
    const date = getZero(now.getDate());
    const hours = getZero(now.getHours());
    const minutes = getZero(now.getMinutes());
    return `${year}년 ${month}월 ${date}일 ${hours}시 ${minutes}분`;
  };

  const [cvrg_list, setCvrg_list] = useState<any>([]);
  const [info, setInfoList] = useState<any>([]);
  const getCerti = () => {
    const sendData = {
      conct_number: paramId,
    };
    reqPost(api.certificate, sendData, (result: any) => {
      if (result.data.resultinfo.result_div_cd === "OK") {
        setInfoList(result.data.d);
        setCvrg_list(result.data.d.cvrg_list);
      } else {
        alert(result.data.resultinfo.result_msg);
        window.close();
      }
    });
  };

  useEffect(() => {
    getCerti();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${info.conct_number}`,
  });

  return (
    <div className="certi-container">
      <h1 className="certi-title">국문 가입 증명서</h1>
      <div className="certi-inner" ref={componentRef}>
        <div className="certi-pdf-btn">
          <button onClick={handlePrint}>PDF 저장</button>
        </div>
        <section className="certi-header">
          <h3>토글보험 가입확인서</h3>
          <div className="certi-logo">
            <img src={Logo} className="logo" alt="로고" />
          </div>
        </section>
        <div>
          <section className="certi-table">
            <div className="certi-table-title">
              <h4>기본정보</h4>
              <h4>계약번호: {info.conct_number}</h4>
            </div>
            <div className="certi-table-content">
              <div className="certi-table-row">
                <BasicInfoTable
                  title1={"보험사명"}
                  content1={info.insurcom_name}
                  title2={"계약상태"}
                  content2={info.member_si_prd_state_name}
                />
              </div>
              <div className="certi-table-row">
                <BasicInfoTable
                  title1={"상품명"}
                  content1={info.prod_name}
                  title2={"상품플랜"}
                  content2={info.plangrd_name}
                />
              </div>
              <div className="certi-table-row">
                <BasicInfoTable
                  title1={"가입일시"}
                  content1={info.entryday}
                  title2={"증권번호"}
                  content2={info.sects_number}
                />
              </div>
              <div className="certi-table-row">
                <BasicInfoTable
                  title1={"보험시작일"}
                  content1={info.ins_start_day}
                  title2={"보험종료일"}
                  content2={info.ins_end_day}
                />
              </div>
              <div className="certi-table-row">
                <BasicInfoTable
                  title1={"여행지"}
                  content1={info.obj}
                  title2={"결제금액"}
                  content2={`${moneyComma(`${info.payment_amount}`)}`}
                />
              </div>
            </div>
          </section>
          <section className="certi-table">
            <div className="certi-table-title">
              <h4>판매자정보</h4>
            </div>
            <div className="certi-table-content">
              <div className="certi-table-row">
                <BasicInfoTable
                  title1={"계약자"}
                  content1={info.contractor_name}
                  title2={"사업자등록번호"}
                  content2={info.contractor_number}
                />
              </div>
              <div className="certi-table-row">
                <BasicInfoTable
                  title1={"판매자"}
                  content1={"토글보험서비스(주)"}
                  title2={"계약자등록번호"}
                  content2={"제2020070006호"}
                />
              </div>
            </div>
          </section>
          <section className="certi-table">
            <div className="certi-table-title">
              <h4>수익자 정보</h4>
            </div>
            <div className="certi-table-content">
              <div className="certi-table-row">
                <BasicInfoTable
                  title1={"수익자"}
                  content1={"피보험자 본인"}
                  title2={"사망시수익자"}
                  content2={"법정상속인"}
                />
              </div>
            </div>
          </section>
          <section className="certi-table">
            <InsuredPersonInfo noinsd_list={info.noinsd_list} type="ko" />
          </section>
          <section className="certi-table warranty">
            <div className="certi-table-title">
              <h4>보장내용</h4>
            </div>
            <div className="certi-table-content">
              <Warranty cvrg_list={cvrg_list} type="ko" />
            </div>
          </section>
          <section className="certi-table">
            <Notice prctn_desctn={info.prctn_desctn} />
          </section>

          <section className="certi-table">
            <div className="certi-table-title">
              <h4>실명확인 담당자</h4>
            </div>
            <div>
              <div>
                <BasicInfoTable
                  title1={"이름/소속"}
                  content1={"토글보험서비스 (주)"}
                  title2={"발행일"}
                  content2={getDate()}
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
