import { useEffect, useState } from "react";
import styles from "./noticedetail.module.css";
import { reqPost } from "../../../libs/requests";
import api from "../../../libs/api";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Background from "../../../components/common/background/Background";
import Title from "../../../components/common/title/Title";
import { style } from "@mui/system";

const NoticeDetail = (props: any) => {
  let param: any = useParams();

  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [regday, setRegDay] = useState("");
  const getData = () => {
    const sendData = { noti_number: param.id };
    reqPost(api.notiinfo, sendData, (result: any) => {
      const data = result.data.d;
      setRegDay(data.regday);
      setTitle(data.title);
      setContent(data.content);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={styles.container}>
      <Title title={"공지사항"} />
      <Background>
        <div className={styles.padding}>
          <h4>
            {"["}등록일: {regday}
            {"]"}
          </h4>
          <section className={styles.mainContainer}>
            <div className={styles.titleArea}>
              <h4 className={styles.title}>{title}</h4>
            </div>
            <div className={styles.contentArea}>
              <textarea disabled value={content} className={styles.content} />
            </div>
          </section>
          <div className={styles.buttonWrapper}>
            <button
              className={styles.button}
              onClick={() => props.history.goBack()}
            >
              목록
            </button>
          </div>
        </div>
      </Background>
    </div>
  );
};

export default NoticeDetail;
