import { useEffect, useState } from "react";
import { reqPost } from "../../../libs/requests";
import api from "../../../libs/api";
import Title from "./common/Title";
import styles from "./step.module.css";
interface Props {
  closeModal: any;
  nextStep: () => void;
  sl_iprd_reg_if: string;
}
const Step4 = ({ closeModal, nextStep, sl_iprd_reg_if }: Props) => {
  const title1 = "■ 보험상품 가입";
  const title2 = "■ STEP4. 알릴사항";
  const [notiList, setNotiList] = useState<any>([]);

  const [checkedItems, setCheckedItems] = useState<any>({});
  const [checkedItemsNo, setCheckedItemsNo] = useState<any>({});
  const [nextOpen, setNextOpen] = useState<boolean>(false);
  const getUnderWriting = () => {
    const sendData = {
      sl_iprd_reg_if: sl_iprd_reg_if,
    };
    reqPost(api.underwriting, sendData, (result: any) => {
      setNotiList(result.data.d);
    });
  };
  useEffect(() => {
    getUnderWriting();
  }, [checkedItems, checkedItemsNo]);

  const handleChange = (event: any, idx: number, yn: string) => {
    Object.keys(checkedItemsNo).map((item) => {
      const res = item.indexOf("n" + idx);
      if (res !== -1) {
        setCheckedItemsNo({
          ...checkedItemsNo,
          [item]: false,
        });
      }
    });
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };
  const handleChange2 = (event: any, idx: number, yn: string) => {
    Object.keys(checkedItems).map((item) => {
      const res = item.indexOf("y" + idx);
      if (res !== -1) {
        setCheckedItems({
          ...checkedItems,
          [item]: false,
        });
      }
    });

    setCheckedItemsNo({
      ...checkedItemsNo,
      [event.target.name]: event.target.checked,
    });
  };
  useEffect(() => {
    let nCheck = 0;
    for (let i = 0; i < notiList.length; i++) {
      const val = `comment_status_n${i}`;
      if (checkedItemsNo[val]) {
        nCheck++;
      }
    }
    if (notiList.length > 0) {
      if (nCheck === notiList.length) {
        setNextOpen(true);
      } else {
        setNextOpen(false);
      }
    }
  }, [checkedItemsNo]);
  const handleChangeAllN = () => {
    let obj: any = {};
    let objY: any = {};
    for (let i = 0; i < notiList.length; i++) {
      let val = `comment_status_n${i}`;
      let valY = `comment_status_y${i}`;
      obj[val] = true;
      objY[valY] = false;
    }
    setCheckedItems(objY);
    setCheckedItemsNo(obj);
    setNextOpen(true);
  };
  return (
    <div className={styles.container}>
      <Title title={title1} closeModal={closeModal} />
      <div className={styles.inner}>
        <span className={styles.title}>{title2}</span>
        <div className={styles.description}>
          가입하시는 고객의 건강상태를 확인해주세요. 보상시 불이익이 발생할 수
          있습니다.
        </div>
        <table className={styles.tableContainer}>
          <colgroup>
            <col style={{ width: "7%" }} />
            <col style={{ width: "75%" }} />
            <col style={{ width: "9%" }} />
            <col style={{ width: "9%" }} />
          </colgroup>
          <thead>
            <tr>
              <th className={styles.tableTitle} colSpan={2}>
                보험가입 전 알릴사항
              </th>
              <th className={styles.tableTitle} colSpan={2}>
                선택
              </th>
            </tr>
          </thead>
          <tbody>
            {notiList.map((item: any, idx: number) => (
              <tr key={idx}>
                <td className={styles.tableSelect}>{item.sort_seqen}</td>
                <td className={styles.tableMainText}>
                  {item.qt}
                  <p />
                  <div className={styles.tableMainDescription}>
                    {item.answer}
                  </div>
                </td>
                <td
                  className={styles.tableSelect}
                  style={{
                    backgroundColor: checkedItems["comment_status_y" + idx]
                      ? "#F8F9FA"
                      : "white",
                  }}
                >
                  <label htmlFor={"comment_status_y" + idx}>예</label>
                  {item.name}
                  <input
                    type="checkbox"
                    name={"comment_status_y" + idx}
                    id={"comment_status_y" + idx}
                    checked={checkedItems["comment_status_y" + idx]}
                    onChange={(e) => handleChange(e, idx, "Y")}
                    style={{ display: "none" }}
                  />
                </td>

                <td
                  className={styles.tableSelect}
                  style={{
                    color: checkedItemsNo["comment_status_n" + idx]
                      ? "#545CD8"
                      : "#000",

                    backgroundColor: checkedItemsNo["comment_status_n" + idx]
                      ? "#F8F9FA"
                      : "white",
                  }}
                >
                  <label htmlFor={"comment_status_n" + idx}>아니오</label>
                  <input
                    type="checkbox"
                    name={"comment_status_n" + idx}
                    id={"comment_status_n" + idx}
                    checked={checkedItemsNo["comment_status_n" + idx]}
                    onChange={(e) => handleChange2(e, idx, "N")}
                    style={{ display: "none" }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.allSelectBtn}>
          <button onClick={() => handleChangeAllN()}>모두 아니오</button>
        </div>
        <div className={styles.bottomBtn}>
          <button
            onClick={nextStep}
            disabled={!nextOpen}
            style={{ backgroundColor: nextOpen ? "#545cd8" : "#848bf2" }}
          >
            다음으로
          </button>
        </div>
      </div>
    </div>
  );
};

export default Step4;
