import TableTwoCol from "../../common/table/tableTwoCol/TableTwoCol";
import "../../../pages/user/userMng/userMng.css";

interface Props {
  basicInfo: {
    copncom_name: string;
    bizregno: string;
    regday: string;
    email: string;
  };
  onToggle: () => void;
}
const BasicInfo = ({ basicInfo, onToggle }: Props) => {
  return (
    <section className="user-mng-table">
      <div className="user-mng-table-title">
        <h2>기본정보</h2>
      </div>
      <table className="user-mng-table-main">
        <TableTwoCol title={"파트너명"} content={basicInfo.copncom_name} />
        <TableTwoCol title={"사업자등록번호"} content={basicInfo.bizregno} />
        <TableTwoCol title={"등록일"} content={basicInfo.regday} />
        <TableTwoCol
          title={"이메일"}
          content={
            <div>
              {basicInfo.email}
              <span className="user-mng-pwd-button">
                <button onClick={onToggle}>비밀번호 변경</button>
              </span>
            </div>
          }
        />
      </table>
    </section>
  );
};

export default BasicInfo;
