import { useState, useEffect } from "react";
import "./noticeList.css";
import { useHistory } from "react-router-dom";
import { reqPost } from "../../../libs/requests";
import api from "../../../libs/api";
import { Pagination } from "antd";
import Title from "../../../components/common/title/Title";
import Background from "../../../components/common/background/Background";
import DataTable from "../../../components/notice/dataTable/DataTable";
import Search from "../../../assets/icons/ci_search.png";

const NoticeList = (props: any) => {
  const history = useHistory();
  const HistoryAction = props.history.action;

  const [title, setTitle] = useState<string>("");
  const [contracts, setContracts] = useState<
    { noti_number: string; regday: string; title: string }[]
  >([]);
  const [pageNo, setPageNo] = useState(1);
  const [page_info, setPage_info] = useState({ totalrecord: 1, totalpage: 1 });

  const getDataList = (page_no: number) => {
    const sendData = {
      title: title,
      page_no: page_no,
    };
    window.sessionStorage.setItem("sendData", JSON.stringify(sendData));
    reqPost(api.notiList, sendData, (result: any) => {
      setContracts(result.data.d.list);
      setPage_info(result.data.d.page_info);

      window.sessionStorage.setItem(
        "TableData",
        JSON.stringify(result.data.d.list)
      );
      window.sessionStorage.setItem(
        "PageInfo",
        JSON.stringify(result.data.d.page_info)
      );
    });
  };

  const changePage = (page_no: number) => {
    setPageNo(page_no);
    getDataList(page_no);
  };

  useEffect(() => {
    if (HistoryAction !== "POP") {
      getDataList(pageNo);
    } else {
      setContracts(
        JSON.parse(window.sessionStorage.getItem("TableData") as any)
      );
      setPage_info(
        JSON.parse(window.sessionStorage.getItem("PageInfo") as any)
      );
      setPageNo(
        JSON.parse(window.sessionStorage.getItem("PageInfo") as any).page
      );
    }
  }, []);

  const handleText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };
  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      getDataList(pageNo);
    }
  };

  const listItem = () => {
    return contracts.map((item, index) => (
      <tr
        key={index}
        onClick={() => history.push(`/notice/${item.noti_number}`)}
      >
        <td>{item.noti_number}</td>
        <td>{item.title}</td>
        <td>{item.regday}</td>
      </tr>
    ));
  };

  return (
    <div className="notice-container">
      <Title title={"공지사항"} />
      <Background>
        <div className="notice-padding">
          <div className="notice-title-search">
            <span className="notice-title-search-title">제목</span>
            <input
              type="text"
              value={title}
              onChange={(e) => handleText(e)}
              onKeyPress={onKeyPress}
              placeholder="search"
            />
            <button onClick={() => getDataList(pageNo)}>
              <img src={Search} />
            </button>
          </div>
          <div style={{ marginTop: 50 }}>
            <DataTable td={listItem()} />
          </div>
          <div style={{ marginTop: 20 }}>
            <Pagination
              current={pageNo}
              defaultCurrent={pageNo}
              total={Number(`${page_info.totalpage}0`)}
              onChange={(page_no: number) => changePage(page_no)}
            />
          </div>
        </div>
      </Background>
    </div>
  );
};

export default NoticeList;
