import Cookies from "universal-cookie";
const { createHash } = require("crypto");
const cookies = new Cookies();

export const itemKey = {
  token: "adm_token_b2b",
  name: "adm_name",
  type: "adm_type",
  agency_name: "agency_name",
  state: "state",
  iprd_list: "iprd_list",
};

export const setItem = (key, value) => {
  //let maxAge = 1800; // 30 minute
  let maxAge = 86400; // 30 minute
  try {
    cookies.set(key, typeof value !== "string" ? String(value) : value, {
      maxAge,
    });
  } catch (error) {
    throw error;
  }
};

export const getItem = (key) => {
  try {
    const value = cookies.get(key);
    return value;
  } catch (error) {
    // error reading value
    throw error;
  }
};

export const removeItem = (key) => {
  cookies.remove(key, { path: "/" });
};

export const encrypt = (pwd) => {
  const hash = createHash("sha256").update(pwd).digest("hex");
  return hash;
};
