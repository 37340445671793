import React from "react";
import "./headText.css";
interface Props {
  title: string;
}

const HeadText = ({ title }: Props) => {
  return (
    <div className="headText">
      <span className="headText-title">{title}</span>
    </div>
  );
};

export default HeadText;
