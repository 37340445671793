import React from "react";
import styles from "./DataTable.module.css";

interface Props {
  td: any;
}

const DataTable = ({ td }: Props) => {
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th className={styles.thread}>No.</th>
          <th className={styles.thread}>제목</th>
          <th className={styles.thread}>등록일</th>
        </tr>
      </thead>
      <tbody>{td}</tbody>
    </table>
  );
};

export default DataTable;
