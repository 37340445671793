import { ChangeEvent, useCallback, useState } from "react";

type InitialValue = {
  title: string;
  value: string;
};
type Props = [{ title: string; value: string }, (e: ChangeEvent) => void];

const useInput = (initialValue: InitialValue): Props => {
  const [input, setInput] = useState(initialValue);

  const handler = useCallback(
    (e) => {
      setInput({ ...initialValue, value: e.target.value.trim() });
    },
    [initialValue]
  );

  return [input, handler];
};
export default useInput;
