export const convertGender = (number: string) => {
  if (number === "1" || number === "3" || number === "5" || number === "7") {
    return "CM00300001";
  } else {
    return "CM00300002";
  }
};
//숫자 3자리마다 콤마찍기
export const moneyComma = (money: number | string) => {
  return money.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};
export function validRegistrationNumber(rn: any, type: string) {
  // 하이픈이 있을지도 모르니까 날려버리자
  // rn = rn.split("-").join("");
  // 13자리 숫자인지 확인해보자, 아니면 꺼져버려
  if (rn.length !== 13) return false;

  // 검증값 합계
  let checkSum = 0;
  for (let i = 0; i < 12; i++)
    checkSum += (rn.substr(i, 1) >> 0) * ((i % 8) + 2);

  // 검증
  const modCheckSum = checkSum % 11; // 검증값 합계의 11의 나머지수
  const rrnMatch = (11 - modCheckSum) % 10 == rn.substr(12, 1); // 주민번호 검증
  const frnMatch = (13 - modCheckSum) % 10 == rn.substr(12, 1); // 외국인번호 검증

  if (type === "rrn") {
    return rrnMatch;
  } else if (type === "frn") {
    return frnMatch;
  }

  // 주민/외국인 여부 상관 없이 등록번호 검사
  return rrnMatch || frnMatch;
}

export const chkResno = (rn: any) => {
  const ssn2f = rn.substr(6, 1);
  let type = "";
  if (ssn2f == "5" || ssn2f == "6" || ssn2f == "7" || ssn2f == "8") {
    type = "frn";
  } else {
    type = "kor";
  }

  rn = rn.split("-").join("");
  if (rn.length !== 13) return false;
  let checkSum = 0;
  for (let i = 0; i < 12; i++)
    checkSum += (rn.substr(i, 1) >> 0) * ((i % 8) + 2);
  const korMatch = (11 - (checkSum % 11)) % 10 == rn.substr(12, 1);
  const frnMatch = (13 - (checkSum % 11)) % 10 == rn.substr(12, 1);

  if (type === "kor") return korMatch;
  else if (type === "frn") return frnMatch;
  else return korMatch || frnMatch;
};
export const verifyPhone = (phone: any) => {
  if (phone.length < 11 || phone.length > 11) {
    return false;
  }
  return true;
};

export const ensure = <T extends unknown>(
  argument: T | undefined | null,
  message: string = "THROW UNDEFINED"
): T => {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }

  return argument;
};

export const validationBusinessNumber = (number: string) => {
  if (number.length === 10) {
    const numberMap = number.split("").map((d: string) => Number(d));
    const keyArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];
    let chk = 0;
    keyArr.forEach((d: number, i: number) => {
      chk += d * numberMap[i];
    });
    chk += Math.floor((numberMap[8] * keyArr[keyArr.length - 1]) / 10);
    chk = chk % 10;
    return numberMap[numberMap.length - 1] === (10 - chk) % 10;
  }
  return false;
};

export const validationLength = (value: string) => {
  if (value.length === 0) {
    return false;
  }
  return true;
};

export const validationEmail = (value: string) => {
  const validation =
    /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
  return validation.test(value);
};
