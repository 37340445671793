export const insurTime = [
  { time: "00:00" },
  { time: "00:30" },
  { time: "01:00" },
  { time: "01:30" },
  { time: "02:00" },
  { time: "02:30" },
  { time: "03:00" },
  { time: "03:30" },
  { time: "04:00" },
  { time: "04:30" },
  { time: "05:00" },
  { time: "05:30" },
  { time: "06:00" },
  { time: "06:30" },
  { time: "07:00" },
  { time: "07:30" },
  { time: "08:00" },
  { time: "08:30" },
  { time: "09:00" },
  { time: "09:30" },
  { time: "10:00" },
  { time: "10:30" },
  { time: "11:00" },
  { time: "11:30" },
  { time: "12:00" },
  { time: "12:30" },
  { time: "13:00" },
  { time: "13:30" },
  { time: "14:00" },
  { time: "14:30" },
  { time: "15:00" },
  { time: "15:30" },
  { time: "16:00" },
  { time: "16:30" },
  { time: "17:00" },
  { time: "17:30" },
  { time: "18:00" },
  { time: "18:30" },
  { time: "19:00" },
  { time: "19:30" },
  { time: "20:00" },
  { time: "20:30" },
  { time: "21:00" },
  { time: "21:30" },
  { time: "22:00" },
  { time: "22:30" },
  { time: "23:00" },
  { time: "23:30" },
];
export const getToday = () => {
  var date = new Date();
  var year = date.getFullYear();
  var month = ("0" + (1 + date.getMonth())).slice(-2);
  var day = ("0" + date.getDate()).slice(-2);

  return year + "-" + month + "-" + day;
};
/**
 *  yyyyMMdd 포맷으로 반환
 */
export function getFormatDate(date) {
  var year = date.getFullYear(); //yyyy
  var month = 1 + date.getMonth(); //M
  month = month >= 10 ? month : "0" + month; //month 두자리로 저장
  var day = date.getDate(); //d
  day = day >= 10 ? day : "0" + day; //day 두자리로 저장
  return year + "-" + month + "-" + day; //'-' 추가하여 yyyy-mm-dd 형태 생성 가능
}
