import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getToday } from "../../libs/time";
import { convertGender } from "../../libs/libcollect";
import { calcAge, calcInsurAge } from "../../libs/age";

type TPayload = {
  departTime: string;
  arriveTime: string;
  departDate: string;
  arriveDate: string;
  journeyPeopleCnt: number;
  nation: string;
};
type JourneyInsurSlicePayload = {
  insurer_name: string;
  insurer_res_reg_no: string; //보험자주민번호
  insu_age: number; //보험나이
  age: number; //나이
  insurer_gender_code: string; //보험자성별코드
};
type TableDataListSlicePayload = {
  tableDataList: {
    idx: number;
  }[];
};
type removeTableDataIdxPayload = {
  idx?: number | null;
  deleteYn?: boolean;
  index?: any;
};
type addTableDataPayload = {
  idx?: number;
  regNumberFirst?: string;
  regNumberLast?: string;
  insurer_name?: string;
  insurer_mphone_number: string;
  insurer_eng_name: string;
};
type InsuranceProductList = {
  insurProductList: any;
};
type Step6Param = {
  conct_number: string;
};
type changeAreaGubun = {
  areaNationGubun: string;
};
type SlProdNumberParam = {
  sl_prod_number: string;
};
type PayLoadNationCode = {
  nationCode: string;
};

const initialState = {
  departTime: "00:00", //출발시간
  arriveTime: "23:59", //도착시간
  departDate: getToday(), //출발일
  arriveDate: getToday(), //도착일
  insurer_name: "", //보험자명
  insurer_res_reg_no: "", //보험자주민번호
  insu_age: 0, //보험나이
  insurer_gender_code: "", //보험자성별코드
  tableDataList: new Array(),
  journeyPeopleCnt: 0, //여행 인원수
  regNumberFirst: "", //주민번호앞자리
  regNumberLast: "", //주민번호 뒷자리
  deleteYn: false,
  index: null, //테이블리스트 번호,
  insurProductList: new Array(), //보험상품리스트
  nation: "", //나라이름
  conct_number: "", //step6파라미터
  areaNationGubun: "", //나라구분
  sl_prod_number: "",
  nationCode: "", //나라코드
};
const journeyInsurSlice = createSlice({
  name: "journeyInsur",
  initialState: initialState,
  reducers: {
    changeVal: (state, { payload }: PayloadAction<TPayload>) => {
      state.departTime = payload.departTime;
      state.arriveTime = payload.arriveTime;
      state.arriveDate = payload.arriveDate;
      state.departDate = payload.departDate;
      state.journeyPeopleCnt = payload.journeyPeopleCnt;
      state.nation = payload.nation;
    },
    changeNationCode: (
      state,
      { payload }: PayloadAction<PayLoadNationCode>
    ) => {
      state.nationCode = payload.nationCode;
    },
    changeJourneyPeople: (
      state,
      { payload }: PayloadAction<JourneyInsurSlicePayload>
    ) => {
      state.insurer_name = payload.insurer_name;
      state.insurer_res_reg_no = payload.insurer_res_reg_no;
      state.insu_age = payload.insu_age;
      state.insurer_gender_code = payload.insurer_gender_code;
    },
    changeTableDataList: (
      state,
      { payload }: PayloadAction<TableDataListSlicePayload>
    ) => {
      state.tableDataList = payload.tableDataList;
    },
    removeTableDataList: (
      state,
      { payload }: PayloadAction<removeTableDataIdxPayload>
    ) => {
      state.tableDataList = state.tableDataList.filter(
        (item) => item.idx !== payload.idx
      );
      state.journeyPeopleCnt = state.journeyPeopleCnt - 1;
    },
    /*테이블 행추가 */
    addTableDataList: (state) => {
      let obj1 = {
        idx: state.tableDataList.length + 1,
        regNumberFirst: "",
        regNumberLast: "",
        insurer_mphone_number: "",
        insurer_eng_name: "",
      };

      state.tableDataList.push(obj1);
      state.journeyPeopleCnt = state.tableDataList.length;
    },
    /*여행자정보 테이블 onChange 이벤트 */
    changeTableDataRow: (
      state,
      { payload }: PayloadAction<addTableDataPayload>
    ) => {
      let tableData = state.tableDataList.find(
        (item) => item.idx === payload.idx
      );
      if (tableData) {
        tableData.regNumberFirst = "";
        tableData.regNumberLast = "";
        tableData.insurer_name = "";
        tableData.insurer_mphone_number = "";
        tableData.insurer_eng_name = "";
      }

      tableData.regNumberLast += payload.regNumberLast;
      tableData.regNumberFirst += payload.regNumberFirst;
      tableData.insurer_name += payload.insurer_name;
      tableData.insurer_mphone_number += payload.insurer_mphone_number;
      tableData.insurer_eng_name += payload.insurer_eng_name;

      tableData.insurer_gender_code = convertGender(
        tableData.regNumberLast.substr(0, 1)
      );

      if (
        tableData.regNumberFirst.length === 6 &&
        tableData.regNumberLast.length > 0
      ) {
        const first = tableData.regNumberFirst.substr(0, 2);
        const second = tableData.regNumberFirst.substr(2, 2);
        const thrid = tableData.regNumberFirst.substr(4, 2);

        const firstDepartDate = state.departDate.substr(2, 2);
        const secondDepartDate = state.departDate.substr(5, 2);
        const thridDepartDate = state.departDate.substr(8, 2);
        const calcBeforeAge = second + "/" + thrid + "/" + first;
        const calcBeforeDeartDate =
          secondDepartDate + "/" + thridDepartDate + "/" + firstDepartDate;

        const regLastFirstNumber = tableData.regNumberLast.substr(0, 1);
        const age = calcAge(calcBeforeAge, regLastFirstNumber);

        const insu_age = calcInsurAge(
          calcBeforeDeartDate,
          calcBeforeAge,
          regLastFirstNumber
        );
        tableData.age = age;
        tableData.insu_age = insu_age;
      } else {
        tableData.age = "";
        tableData.insurAge = "";
        tableData.insu_age = "";
      }
    },
    AddInsuranceProductList: (
      state,
      { payload }: PayloadAction<InsuranceProductList>
    ) => {
      state.insurProductList = payload.insurProductList;
    },
    changeConctNumber: (state, { payload }: PayloadAction<Step6Param>) => {
      state.conct_number = payload.conct_number;
    },
    changeSLProdNumber: (
      state,
      { payload }: PayloadAction<SlProdNumberParam>
    ) => {
      state.sl_prod_number = payload.sl_prod_number;
    },
    changeAreaGubun: (state, { payload }: PayloadAction<changeAreaGubun>) => {
      state.areaNationGubun = payload.areaNationGubun;
    },
  },
});

export const journeyRegisterReducer = journeyInsurSlice.reducer;
export const {
  changeVal,
  changeJourneyPeople,
  changeTableDataList,
  removeTableDataList,
  changeTableDataRow,
  addTableDataList,
  AddInsuranceProductList,
  changeConctNumber,
  changeAreaGubun,
  changeSLProdNumber,
  changeNationCode,
} = journeyInsurSlice.actions;
