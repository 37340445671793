import { calcAge } from "../../../libs/age";
import TableDiffColor from "../../common/table/tableDiffColor/TableDiffColor";
import "../style/basicInfo.css";
import { moneyComma } from "../../../libs/libcollect";

type Props = {
  noinsd_list: {
    individ_prmim: number;
    noinsd_birth: string;
    noinsd_covge_age_grade: string;
    noinsd_gender_name: string;
    noinsd_name: string;
  }[];
  type: string;
};

const InsuredPersonInfo = ({ noinsd_list, type }: Props) => {
  const listItem = () => {
    return noinsd_list?.map((item, index) => (
      <tr key={index}>
        <td>{item.noinsd_name}</td>
        <td>{item.noinsd_birth}</td>
        <td>{item.noinsd_gender_name}</td>
        <td>{item.noinsd_covge_age_grade}</td>
        <td>
          {type === "ko"
            ? moneyComma(item.individ_prmim)
            : `KRW ${moneyComma(item.individ_prmim)}`}
        </td>
      </tr>
    ));
  };

  return (
    <div>
      <div className="certi-table-title">
        <h4>{type === "ko" ? "피보험자 정보" : "Insured List"}</h4>
      </div>
      <TableDiffColor
        th={
          type === "ko"
            ? ["피보험자명", "생년월일", "성별", "연령등급", "보험료"]
            : [
                "Name of Insured",
                "Date of Birth",
                "Sex",
                "Grade of Age",
                "Premium",
              ]
        }
        td={listItem()}
        align="center"
      />
    </div>
  );
};

export default InsuredPersonInfo;
