import { reqPost } from "../../libs/requests";
import { useEffect, useState, memo } from "react";
import api from "../../libs/api";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/";

const Step3 = () => {
  const [notiList, setNotiList] = useState<any>([]);
  const { sl_prod_number } = useSelector(
    (state: RootState) => state.journeyRegister
  );
  const getData = () => {
    const sendData = {
      sl_prod_number: sl_prod_number,
    };
    reqPost(api.needknow, sendData, (result: any) => {
      setNotiList(result.data.d);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div style={{ marginTop: 30 }}>
        <h1>알아두실 사항</h1>
      </div>
      <div>
        {notiList.map((item: any) => (
          <div style={{ marginTop: 30 }}>
            <div style={{ fontWeight: 600, fontSize: 20 }}>{item.title}</div>
            <div>{item.contents}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Step3;
