import { ChangeEvent } from "react";
import styles from "../../pages/cooperateregister/cooperregister.module.css";

interface Props {
  id: string;
  file: File | undefined;
  onChange: (e: ChangeEvent<Element>) => void;
}
const CorInputFile = ({ id, file, onChange }: Props) => {
  return (
    <div className={styles.fileWrapper}>
      <input
        className={styles.uploadInput}
        placeholder="png, jpg, jpeg, pdf"
        disabled
        value={file && file.name}
      />
      <label htmlFor={`${id}`} className={styles.fileButton}>
        첨부
      </label>
      <input
        type="file"
        id={`${id}`}
        style={{ display: "none" }}
        onChange={onChange}
        accept=".png, .jpg, .jpeg, .pdf"
      />
    </div>
  );
};

export default CorInputFile;
