type Props = {
  prctn_desctn: string;
};
const Notice = ({ prctn_desctn }: Props) => {
  return (
    <div>
      {prctn_desctn?.split("\n").map((line, index) => {
        return (
          <span key={index}>
            {line}
            <br />
          </span>
        );
      })}
    </div>
  );
};

export default Notice;
