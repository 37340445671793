import { useEffect, useState } from "react";
import { reqPost } from "../../../libs/requests";
import api from "../../../libs/api";
import Title from "./common/Title";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers/";
import { changeConctNumber } from "../../../redux/reducers/journeyRegisterReducer";
import Modal from "react-modal";
import styles from "./step.module.css";
interface Props {
  closeModal: any;
  nextStep: () => void;
  sl_iprd_reg_if: string;
  total_prmim: number;
  total_prmim_cost: number;
}
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "97%",
    height: "98%",
    zIndex: 100,
  },
};
const Step5 = ({
  closeModal,
  nextStep,
  sl_iprd_reg_if,
  total_prmim,
  total_prmim_cost,
}: Props) => {
  const {
    departDate,
    departTime,
    arriveDate,
    arriveTime,
    nation,
    tableDataList,
    insurProductList,
    areaNationGubun,
  } = useSelector((state: RootState) => state.journeyRegister);
  const title1 = "■ 해외여행보험가입";
  const title2 = "■ STEP5. 약관동의";
  const [terms, setTerms] = useState<any>([]);
  const [checkedItemsYes, setCheckedItemsYes] = useState<any>({});
  const [checkedItemsNo, setCheckedItemsNo] = useState<any>({});
  const [nextOpen, setNextOpen] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [termsIdx, setTermsIdx] = useState<number>(0);

  const dispatch = useDispatch();
  const getStpl = () => {
    const sendData = {
      sl_iprd_reg_if: sl_iprd_reg_if,
    };
    reqPost(api.stpl, sendData, (result: any) => {
      setTerms(result.data.d);
    });
  };
  const afterOpenModal = () => {};
  const closeModal2 = () => {
    setModalIsOpen(false);
  };
  useEffect(() => {
    getStpl();
  }, []);
  useEffect(() => {
    let agreeCnt = 0;
    Object.values(checkedItemsYes).map((item) => {
      if (item) {
        agreeCnt++;
      }
    });
    if (Object.values(checkedItemsYes).length === agreeCnt && agreeCnt !== 0) {
      setNextOpen(true);
    } else {
      setNextOpen(false);
    }
  }, [checkedItemsYes]);
  const handleChangeYes = (event: any, idx: number) => {
    Object.keys(checkedItemsNo).map((item) => {
      const res = item.indexOf("n" + idx);
      if (res !== -1) {
        setCheckedItemsNo({
          ...checkedItemsNo,
          [item]: false,
        });
      }
    });
    setCheckedItemsYes({
      ...checkedItemsYes,
      [event.target.name]: event.target.checked,
    });
  };
  const handleChangeNo = (event: any, idx: number) => {
    Object.keys(checkedItemsYes).map((item) => {
      const res = item.indexOf("y" + idx);
      if (res !== -1) {
        setCheckedItemsYes({
          ...checkedItemsYes,
          [item]: false,
        });
      }
    });

    setCheckedItemsNo({
      ...checkedItemsNo,
      [event.target.name]: event.target.checked,
    });
  };
  const handleChangeAllAgree = () => {
    let objN: any = {};
    let objY: any = {};
    for (let i = 0; i < terms.length; i++) {
      let valN = `comment_status_n${i}`;
      let valY = `comment_status_y${i}`;
      objN[valN] = false;
      objY[valY] = true;
    }
    setCheckedItemsYes(objY);
    setCheckedItemsNo(objN);
  };

  const onSubmit = () => {
    const sendData = {
      sl_iprd_reg_if: sl_iprd_reg_if,
      obj: areaNationGubun === "IP202012020352052YNk" ? "" : nation,
      insu_start_day: departDate + " " + departTime,
      insu_end_day: arriveDate + " " + arriveTime,
      insurer_list: tableDataList,
      total_prmim: total_prmim,
      total_prmim_cost: total_prmim_cost,
    };

    reqPost(api.provisionalreg, sendData, (result: any) => {
      dispatch(
        changeConctNumber({
          conct_number:
            result.data !== undefined ? result.data.d.conct_number : "",
        })
      );
      nextStep();
    });
  };
  return (
    <div className={styles.container}>
      <Title title={title1} closeModal={closeModal} />
      <div className={styles.inner}>
        <span className={styles.title}>{title2}</span>
        <div className={styles.description}>이용약관에 동의하여 주세요.</div>
        <table className={styles.tableContainer}>
          <colgroup>
            <col style={{ width: "7%" }} />
            <col style={{ width: "66%" }} />
            <col style={{ width: "9%" }} />
            <col style={{ width: "9%" }} />
            <col style={{ width: "9%" }} />
          </colgroup>
          <thead>
            <tr>
              <th colSpan={2} className={styles.tableTitle}>
                약관동의
              </th>
              <th className={styles.tableTitle}>약관</th>
              <th colSpan={2} className={styles.tableTitle}>
                동의
              </th>
            </tr>
          </thead>
          <tbody>
            {terms.map((item: any, idx: number) => (
              <tr key={idx} style={{ height: 50 }}>
                <td className={styles.tableSelect}>{idx + 1}</td>
                <td className={styles.tableMainText}>
                  {item.insucomr_stip_name}
                </td>
                <td className={styles.tableContentCenter}>
                  <button
                    className={styles.agreementBtn}
                    onClick={() => {
                      setTermsIdx(idx);
                      setModalIsOpen(true);
                    }}
                  >
                    전문보기
                  </button>
                </td>
                <td
                  className={styles.tableSelect}
                  style={{
                    color: checkedItemsYes["comment_status_y" + idx]
                      ? "#545CD8"
                      : "#000",
                    backgroundColor: checkedItemsYes["comment_status_y" + idx]
                      ? "#F8F9FA"
                      : "white",
                  }}
                >
                  <label htmlFor={"comment_status_y" + idx}>예</label>
                  {item.name}
                  <input
                    type="checkbox"
                    name={"comment_status_y" + idx}
                    id={"comment_status_y" + idx}
                    checked={checkedItemsYes["comment_status_y" + idx]}
                    onChange={(e) => handleChangeYes(e, idx)}
                    style={{ display: "none" }}
                  />
                </td>
                <td
                  className={styles.tableSelect}
                  style={{
                    color: checkedItemsNo["comment_status_n" + idx]
                      ? "#545CD8"
                      : "#000",
                    backgroundColor: checkedItemsNo["comment_status_n" + idx]
                      ? "#F8F9FA"
                      : "white",
                  }}
                >
                  <label htmlFor={"comment_status_n" + idx}>아니오</label>
                  <input
                    type="checkbox"
                    name={"comment_status_n" + idx}
                    id={"comment_status_n" + idx}
                    checked={checkedItemsNo["comment_status_n" + idx]}
                    onChange={(e) => handleChangeNo(e, idx)}
                    style={{ display: "none" }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.allSelectBtn}>
          <button onClick={handleChangeAllAgree}>모두 동의</button>
        </div>
        <div className={styles.bottomBtn}>
          <button
            onClick={onSubmit}
            disabled={!nextOpen}
            style={{ backgroundColor: nextOpen ? "#545cd8" : "#848bf2" }}
          >
            다음으로
          </button>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal2}
        style={customStyles}
      >
        <button
          style={{ float: "right" }}
          onClick={() => setModalIsOpen(false)}
        >
          닫기
        </button>
        {terms.length > 0 && (
          <div
            dangerouslySetInnerHTML={{
              __html: terms[termsIdx].insucomr_stip_content,
            }}
          />
        )}
      </Modal>
    </div>
  );
};

export default Step5;
