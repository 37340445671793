import "./sidebar.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { itemKey, getItem } from "../../libs/preference";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

interface Props {
  copncom_name: string;
}

export default function Sidebar({ copncom_name }: Props) {
  const history = useHistory();
  let token = getItem(itemKey.token);

  const sideArea = () => {
    return (
      <div className="sidebar">
        <ProSidebar style={{ backgroundColor: "white" }}>
          <Menu iconShape="square">
            {navList.map((item, index) =>
              item.list.length !== 0 ? (
                <SubMenu title={item.title} key={index}>
                  {item.list.map((item2, index2) => (
                    <span className="submenu" key={index2}>
                      {item2.activated ? (
                        <MenuItem>
                          <span
                            className="submenu-text"
                            onClick={() => history.push(`${item2.link}`)}
                          >{`${item2.title}`}</span>
                        </MenuItem>
                      ) : (
                        <MenuItem>
                          <span className="submenu-untext">{item2.title}</span>
                        </MenuItem>
                      )}
                    </span>
                  ))}
                </SubMenu>
              ) : copncom_name === "관리자" ? (
                item.title === "사용자관리" ? (
                  <></>
                ) : (
                  <MenuItem key={index}>
                    <Link to={item.link}>{item.title}</Link>
                  </MenuItem>
                )
              ) : (
                <MenuItem key={index}>
                  <Link to={item.link}>{item.title}</Link>
                </MenuItem>
              )
            )}
          </Menu>
        </ProSidebar>
      </div>
    );
  };

  return <>{token && sideArea()}</>;
}

const navList = [
  {
    title: "보험가입",
    link: "",
    list: [
      {
        title: "해외여행자보험",
        link: "/overseas-travel-in-surance/online-quote",
        activated: true,
      },
      {
        title: "국내여행자보험",
        link: "/domestic-travel-in-surance/online-quote",
        activated: true,
      },
      {
        title: "장기체류보험(예정)",
        link: "",
        activated: false,
      },
    ],
  },
  {
    title: "계약관리",
    link: "",
    list: [
      {
        title: "계약리스트",
        link: "/contract-management",
        activated: true,
      },
    ],
  },
  {
    title: "공지사항",
    link: "/notice",
    list: [],
  },
  { title: "사용자관리", link: "/usermng", list: [] },
];
