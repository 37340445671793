import { memo } from "react";
import styles from "./TableTwoCol.module.css";

interface Props {
  title: string | JSX.Element;
  content: string | JSX.Element;
}
const TableTwoCol = ({ title, content }: Props) => {
  return (
    <tr className={styles.container}>
      <td>{title}</td>
      <td>{content}</td>
    </tr>
  );
};

export default TableTwoCol;
