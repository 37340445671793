import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import "./loading.css";

const Loding = () => {
  return (
    <div className="loading">
      <div className="loading-shape">
        <ScaleLoader
          height="160px"
          width="32px"
          color="#4254ec"
          radius="8px"
          loading={true}
        />
      </div>
    </div>
  );
};

export default Loding;
