import "./button.css";

interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  title: string;
}
const Button = ({ onClick, title }: Props) => {
  return (
    <div className="button-format">
      <button onClick={onClick}>{title}</button>
    </div>
  );
};

export default Button;
