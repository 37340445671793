import TableDiffColor from "../../common/table/tableDiffColor/TableDiffColor";
import { moneyComma } from "../../../libs/libcollect";

interface Props {
  handlePaymentCancel: () => void;
  paymentInfo: {
    payment_amount: number;
    payment_cancelday: string;
    payment_day: string;
    payment_mean: string;
    payment_state_name: string;
    pmtagency_name: string;
    reg_seq: number;
  }[];
}

const PaymentInfo = ({ paymentInfo, handlePaymentCancel }: Props) => {
  const listItem = () => {
    return paymentInfo.map((item: any, idx: number) => (
      <tr key={idx}>
        <td>{item.payment_day}</td>
        <td>{item.payment_state_name}</td>
        <td>{item.pmtagency_name}</td>
        <td>{item.payment_mean}</td>
        <td>{moneyComma(item.payment_amount)}</td>
        <td>
          {item.payment_state_name === "결제완료" ? (
            <button
              onClick={() => handlePaymentCancel()}
              className="btn-cancel"
            >
              결제취소
            </button>
          ) : (
            ""
          )}
        </td>
      </tr>
    ));
  };

  return (
    <section className="constract-inner">
      <h4 className="constract-title">■ 결제 정보</h4>
      <TableDiffColor
        th={["결제일", "결제상태", "결제대행사", "결제수단", "결제금액", ""]}
        td={listItem()}
        align="center"
      />
    </section>
  );
};

export default PaymentInfo;
