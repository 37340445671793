import styles from "../../pages/cooperateregister/cooperregister.module.css";

interface Props {
  children: JSX.Element;
}
const InputWrapper = ({ children }: Props) => {
  return <div className={styles.input}>{children}</div>;
};

export default InputWrapper;
