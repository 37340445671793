import styles from "../../pages/cooperateregister/cooperregister.module.css";

interface Props {
  title: string;
}
const CorTitle = ({ title }: Props) => {
  return (
    <h4 className={styles.inputTitle}>
      {title}{" "}
      <span className={styles.important}>
        {"("}*{")"}
      </span>
    </h4>
  );
};

export default CorTitle;
