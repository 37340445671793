import { reqPost } from "../../libs/requests";
import { useEffect, useState, memo } from "react";
import styles from "./step2.module.css";
import api from "../../libs/api";
import TableDiffColor from "../../components/common/table/tableDiffColor/TableDiffColor";
import { moneyComma } from "../../libs/libcollect";

interface Props {
  sl_prod_number: string;
}

const Step2 = ({ sl_prod_number }: Props) => {
  const [cvrgList, setCvrgList] = useState<any>([]);
  const [filteredCvrgList, setFilteredCvrgList] = useState<any>([]);
  const [itemAge_grade_name, setItemAge_grade_name] = useState<string>("");
  const [itemCovge_age_start, setItemCovge_age_start] = useState<number>(0);
  const [itemCovge_age_end, setItemCovge_age_end] = useState<number>(0);
  const [btnIdx, setBtnIdx] = useState<number>(0);
  const getData = () => {
    const sendData = {
      sl_prod_number: sl_prod_number,
    };

    reqPost(api.cvrginfo, sendData, (result: any) => {
      setCvrgList(result.data.d.cvrg);
    });
  };

  useEffect(() => {
    getData();
  }, []);
  const initData = async () => {
    const cvrgs = await cvrgList.filter(
      (item: any) => item.age_grade_reg_if === "AG202109081426A0001"
    );
    setFilteredCvrgList(cvrgs);
    if (cvrgs.length > 0) {
      setItemAge_grade_name(cvrgs[0].age_grade_name);
      setItemCovge_age_start(cvrgs[0].covge_age_start);
      setItemCovge_age_end(cvrgs[0].covge_age_end);
    }
  };
  useEffect(() => {
    initData();
  }, [cvrgList]);

  const titleClick = (
    index: number,
    age_grade_reg_if: string,
    age_grade_name: string,
    covge_age_start: number,
    covge_age_end: number
  ) => {
    const cvrgs = cvrgList.filter(
      (item: any) => item.age_grade_reg_if === age_grade_reg_if
    );
    setFilteredCvrgList(cvrgs);
    setItemAge_grade_name(age_grade_name);
    setItemCovge_age_start(covge_age_start);
    setItemCovge_age_end(covge_age_end);
    setBtnIdx(index);
  };

  const renderTableRow = () => {
    return filteredCvrgList.map((cvrgList: any) => (
      <>
        {cvrgList.list.map((item: any, index: number) => (
          <tr>
            <td style={{ textIndent: 10 }}>{item.covge_name}</td>
            <td colSpan={3} style={{ textIndent: 10 }}>
              {moneyComma(item.covge_exposure_amount) +
                item.covge_exposure_amount_name}
            </td>
          </tr>
        ))}
      </>
    ));
  };

  return (
    <div className={styles.container}>
      <h1 className="insurance-content-container-title">보장 내용</h1>
      <div className={styles.insur_content_btn_container}>
        {cvrgList.map((item: any, index: number) => (
          <>
            <button
              style={{
                backgroundColor: btnIdx === index ? "#444054" : "#F7F7F7",
                color: btnIdx === index ? "#fff" : "#6C757D",
              }}
              // className={styles.insur_content_btn}
              className="insurance-content-container-under-btn"
              onClick={() =>
                titleClick(
                  index,
                  item.age_grade_reg_if,
                  item.age_grade_name,
                  item.covge_age_start,
                  item.covge_age_end
                )
              }
            >
              <div>
                {item.age_grade_name}{" "}
                {"(" + item.covge_age_start + "~" + item.covge_age_end + "세)"}
              </div>
            </button>
          </>
        ))}
      </div>
      <div style={{ marginTop: 30 }}>
        <TableDiffColor
          th={["담보명", "보장 금액"]}
          td={renderTableRow()}
          align="center"
        />
      </div>
      {/* <table style={{ marginTop: 30 }}>
        <thead>
          <tr style={{ backgroundColor: "#000", color: "#fff" }}>
            <th>담보명</th>
            <th>
              <div>{itemAge_grade_name}</div>
              <div>
                {" "}
                {"(" + itemCovge_age_start + "~" + itemCovge_age_end + "세)"}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <>
            {filteredCvrgList.map((cvrgList: any) => (
              <>
                {cvrgList.list.map((item: any, index: number) => (
                  <tr>
                    <td>{item.covge_name}</td>
                    <td colSpan={3}>
                      {item.covge_exposure_amount +
                        item.covge_exposure_amount_name}
                    </td>
                  </tr>
                ))}
              </>
            ))}
          </>
        </tbody>
      </table> */}
    </div>
  );
};

export default memo(Step2);
