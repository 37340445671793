import TableTwoCol from "../../common/table/tableTwoCol/TableTwoCol";
import "../../../pages/user/userMng/userMng.css";
interface Props {
  userInfo: {
    admin_name: string;
    officer_email: string;
    mphone_number: string;
  };
}

const UserInfo = ({ userInfo }: Props) => {
  return (
    <section className="user-mng-table">
      <div className="user-mng-table-title">
        <h2>사용자 정보</h2>
      </div>
      <table className="user-mng-table-main">
        <TableTwoCol title={"담당자명"} content={userInfo.admin_name} />
        <TableTwoCol title={"이메일"} content={userInfo.officer_email} />
        <TableTwoCol title={"휴대폰"} content={userInfo.mphone_number} />
      </table>
    </section>
  );
};

export default UserInfo;
