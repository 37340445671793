import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./insurProductForm.css";
import JourneyInfo from "../../components/insurproduct/JourneyInfo";
import JourneyPeopleInfo from "../../components/insurproduct/JourneyPeopleInfo";
import InsuranceProductList from "../../components/insurproduct/InsuranceProductList";
import HeadText from "../../components/insurproduct/HeadText";
import Button from "../../components/common/Button";
import Title from "../../components/common/title/Title";
import { RootState } from "../../redux/reducers/";
import {
  changeTableDataList,
  changeVal,
  AddInsuranceProductList,
  changeAreaGubun,
  addTableDataList,
} from "../../redux/reducers/journeyRegisterReducer";
import { reqPost } from "../../libs/requests";
import api from "../../libs/api";
import Loading from "../../components/common/Loding";
import Modal from "react-modal";
import Register from "../../components/insurproduct/modal/Register";
import { validRegistrationNumber } from "../../libs/libcollect";
// IP20201202035154rjQR 해외여행보험
// IP202012020352052YNk 국내여행보험

const InsurProductForm = ({ match }: any) => {
  const [areaGubun, setAreaGubun] = useState<string>("");
  const [bigTitle, setBigTitle] = useState<string>("해외여행보험가입");
  const title1 = "■ STEP1. 여행정보 입력";
  const title2 = "■ STEP2. 여행자정보 입력";
  const title3 = "■ STEP3. 보험상품 선택";

  const dispatch = useDispatch();
  const {
    departTime,
    arriveTime,
    departDate,
    arriveDate,
    tableDataList,
    nation,
  } = useSelector((state: RootState) => state.journeyRegister);
  const [resInsuranceProductCheck, setResInsuranceProductCheck] =
    useState<boolean>(false); //보험리스트 있는지 체크
  let [loading, setLoading] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sl_iprd_reg_if, setSl_iprd_reg_if] = useState<string>(""); //모달 구매키
  const [total_prmim, setTotal_prmim] = useState<number>(0);
  const [total_prmim_cost, setTotal_prmim_cost] = useState<number>(0);
  const [insurCalcBtnClick, setInsurCalcBtnClick] = useState<boolean>(false);

  let subtitle: any;
  function openModal() {
    setModalIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModal() {
    setModalIsOpen(false);
  }
  const caculateInsurBtn = () => {
    let insurer_list = Array();
    setInsurCalcBtnClick(true);
    if (areaGubun === "IP20201202035154rjQR") {
      if (nation === "") {
        return alert("여행지역을 선택해주세요.");
      }
    }

    tableDataList.map((item, idx: number) => {
      const insurer_listObj = {
        idx: item.idx,
        insurer_name: item.insurer_name,
        insurer_res_reg_no: item.regNumberFirst + item.regNumberLast,
        insu_age: item.insu_age,
        age: item.age,
        insurer_gender_code: item.insurer_gender_code,
        insurer_eng_name: item.insurer_eng_name,
        insurer_mphone_number: item.insurer_mphone_number,
      };

      if (
        !(
          validRegistrationNumber(
            item.regNumberFirst + item.regNumberLast,
            "rrn"
          ) ||
          validRegistrationNumber(
            item.regNumberFirst + item.regNumberLast,
            "frn"
          )
        )
      ) {
        window.alert(
          idx + 1 + "번째 피보험자의 주민등록번호에 오류가 있습니다."
        );
        return;
      }
      insurer_list.push(insurer_listObj);
    });

    const res = tableDataList.map((tableData, idx: number) => {
      if (areaGubun === "IP20201202035154rjQR") {
        if (tableData.insurer_eng_name === "") {
          window.alert(idx + 1 + "번째 영문명을 채워주세요");
          return "error";
        }
      } else if (tableData.regNumberFirst === "") {
        window.alert(idx + 1 + "번째 주민번호를 채워주세요");
        return "error";
      } else if (tableData.insurer_mphone_number === "") {
        window.alert(idx + 1 + "번째 휴대폰번호를 채워주세요");
        return "error";
      } else if (tableData.insurer_name === "") {
        window.alert(idx + 1 + "번째 피보험자명을 채워주세요");
        return "error";
      }
      return "";
    });
    const errorValid = res.find((item: any) => item === "error");

    const sendData = {
      iprd_reg_if: areaGubun,
      insu_start_day: departDate + " " + departTime,
      insu_end_day: arriveDate + " " + arriveTime,
      insurer_list: insurer_list,
    };

    if (tableDataList.length === 0) {
      return alert("여행자정보를 입력해주세요.");
    }
    if (insurer_list.length !== tableDataList.length) {
      return;
    }
    //순차적으로 map돌면서 에러인경우 리턴해버림
    if (errorValid === "error") {
      return;
    }

    setLoading(true);
    reqPost(api.primim, sendData, (result: any) => {
      if (result.data.resultinfo.result_div_cd === "OK") {
        setResInsuranceProductCheck(true);
        setLoading(false);
        dispatch(
          AddInsuranceProductList({
            insurProductList: result.data.d === null ? [] : result.data.d,
          })
        );
      } else {
        alert(result.data.resultinfo.result_msg);
      }
    });
  };

  useEffect(() => {
    if (match.url.indexOf("overseas") === 1) {
      setAreaGubun("IP20201202035154rjQR");
      setBigTitle("해외여행보험가입");
      dispatch(
        changeAreaGubun({
          areaNationGubun: "IP20201202035154rjQR",
        })
      );
    } else if (match.url.indexOf("domestic") === 1) {
      setAreaGubun("IP202012020352052YNk");
      setBigTitle("국내여행보험가입");
      dispatch(
        changeAreaGubun({
          areaNationGubun: "IP202012020352052YNk",
        })
      );
    }
    dispatch(
      AddInsuranceProductList({
        insurProductList: [],
      })
    );
    dispatch(
      changeTableDataList({
        tableDataList: [],
      })
    );
    dispatch(addTableDataList());
  }, [match.path]);

  const handlePeople = (cnt: number) => {
    if (cnt > 150) {
      alert("여행인원은 최대 150명까지 등록 가능합니다.");
    } else {
      dispatch(
        changeVal({
          departTime: departTime,
          arriveTime: arriveTime,
          departDate: departDate,
          arriveDate: arriveDate,
          journeyPeopleCnt: cnt,
          nation: nation,
        })
      );
      //인원변경시 step3계산결과 초기화
      dispatch(
        AddInsuranceProductList({
          insurProductList: [],
        })
      );

      const testjsonDataList = [];

      for (let i = 1; i <= cnt; i++) {
        let obj1 = {
          idx: i,
          insurer_name:
            tableDataList[i - 1] !== undefined
              ? tableDataList[i - 1].insurer_name
              : "",
          insurer_eng_name:
            tableDataList[i - 1] !== undefined
              ? tableDataList[i - 1].insurer_eng_name
              : "",
          regNumberFirst:
            tableDataList[i - 1] !== undefined
              ? tableDataList[i - 1].regNumberFirst
              : "",
          regNumberLast:
            tableDataList[i - 1] !== undefined
              ? tableDataList[i - 1].regNumberLast
              : "",
          insurer_mphone_number:
            tableDataList[i - 1] !== undefined
              ? tableDataList[i - 1].insurer_mphone_number
              : "",
          insu_age:
            tableDataList[i - 1] !== undefined
              ? tableDataList[i - 1].insu_age
              : "",
          age:
            tableDataList[i - 1] !== undefined ? tableDataList[i - 1].age : "",
          insurer_gender_code:
            tableDataList[i - 1] !== undefined
              ? tableDataList[i - 1].insurer_gender_code
              : "",
        };
        testjsonDataList.push(obj1);
      } //테이블 추가 액션
      dispatch(
        changeTableDataList({
          tableDataList: testjsonDataList,
        })
      );
    }
    return cnt;
  };
  return (
    <div className="insur-product-container">
      <Title title={bigTitle} />
      <div className="insur-product-background">
        <section className="insur-product-step-inner">
          <HeadText title={title1} />
          <JourneyInfo handlePeople={handlePeople} areaGubun={areaGubun} />
        </section>
        <section className="insur-product-step-inner">
          <JourneyPeopleInfo areaGubun={areaGubun} title2={title2} />
        </section>
        <section className="insur-cal-button">
          <Button onClick={caculateInsurBtn} title={"보험료 계산"} />
        </section>
        <section className="insur-product-step-inner">
          <HeadText title={title3} />
          <InsuranceProductList
            areaGubun={areaGubun}
            modalIsOpen={modalIsOpen}
            setModalIsOpen={setModalIsOpen}
            setSl_iprd_reg_if={setSl_iprd_reg_if}
            setTotal_prmim={setTotal_prmim}
            setTotal_prmim_cost={setTotal_prmim_cost}
            insurCalcBtnClick={insurCalcBtnClick}
          />
        </section>
        {loading && <Loading />}
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={{
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "#00000033",
            },
            content: {
              position: "absolute",
              overflow: "auto",
              outline: "none",
              width: "65vw",
              height: "90vh",
              margin: "auto",
              borderRadius: "8px",
              padding: 0,
            },
          }}
        >
          {sl_iprd_reg_if.length > 0 && (
            <Register
              closeModal={closeModal}
              sl_iprd_reg_if={sl_iprd_reg_if}
              total_prmim={total_prmim}
              total_prmim_cost={total_prmim_cost}
            />
          )}
        </Modal>
      </div>
    </div>
  );
};

export default InsurProductForm;
