import React, { useEffect, useState } from "react";
import "../../style/basicInfo.css";
import Warranty from "../../common/Warranty";
import { reqPost } from "../../../../libs/requests";
import api from "../../../../libs/api";
import { moneyComma } from "../../../../libs/libcollect";
import { useReactToPrint } from "react-to-print";
import BasicInfoTable from "../../common/BasicInfoTable";
import InsuredPersonInfo from "../../common/InsuredPersonInfo";
import Logo from "../../../../assets/images/toggle-logo-web-main.svg";
interface Props {
  paramId: string;
}

const BasicInfoEn = ({ paramId }: Props) => {
  const [cvrg_list, setCvrg_list] = useState<any>([]);
  const [info, setInfoList] = useState<any>([]);

  const getCerti = () => {
    const sendData = {
      conct_number: paramId,
    };
    reqPost(api.engcertificate, sendData, (result: any) => {
      if (result.data.resultinfo.result_div_cd === "OK") {
        setInfoList(result.data.d);
        setCvrg_list(result.data.d.cvrg_list);
      } else {
        alert(result.data.resultinfo.result_msg);
        window.close();
      }
    });
  };

  useEffect(() => {
    getCerti();
  }, []);

  const componentRef: any = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${info.conct_number}`,
  });

  const getDate = () => {
    let now = new Date();
    const getZero = (value: number) => {
      if (value < 10) {
        return `0${value}`;
      } else return value;
    };
    const year = now.getFullYear();
    const month = getZero(now.getMonth() + 1);
    const date = getZero(now.getDate());
    const hours = getZero(now.getHours());
    const minutes = getZero(now.getMinutes());
    return `${year}-${month}-${date} / ${hours}:${minutes}`;
  };

  return (
    <div className="certi-container">
      <h1 className="certi-title">영문 가입 증명서</h1>

      <div className="certi-inner" ref={componentRef}>
        <div className="certi-pdf-btn">
          <button onClick={handlePrint}>PDF 저장</button>
        </div>
        <section className="certi-header">
          <h3>
            Confirmation of Coverage<div>Toggle Insurance</div>
          </h3>
          <div className="certi-logo">
            <img src={Logo} className="logo" alt="로고" />
          </div>
        </section>
        <div>
          <section className="certi-table">
            <div className="certi-table-title">
              <h4>Basic Information</h4>
              <h4>Toggle Contract No. {info.conct_number}</h4>
            </div>
            <div className="certi-table-content">
              <div className="certi-table-row">
                <BasicInfoTable
                  title1={"Insurer"}
                  content1={info.insurcom_name}
                  title2={"Status"}
                  content2={info.member_si_prd_state_name}
                />
              </div>
              <div className="certi-table-row">
                <BasicInfoTable
                  title1={"Type of Insurance"}
                  content1={info.prod_name}
                  title2={"Plan"}
                  content2={info.plangrd_name}
                />
              </div>
              <div className="certi-table-row">
                <BasicInfoTable
                  title1={"Date of Issue"}
                  content1={info.entryday}
                  title2={"Policy No."}
                  content2={info.sects_number}
                />
              </div>
              <div className="certi-table-row">
                <BasicInfoTable
                  title1={"Policy Period"}
                  content1={info.ins_start_day}
                  title2={"End Date"}
                  content2={info.ins_end_day}
                />
              </div>
              <div className="certi-table-row">
                <BasicInfoTable
                  title1={"Destination"}
                  content1={info.obj}
                  title2={"Total Premium"}
                  content2={`KRW ${moneyComma(`${info.payment_amount}`)}`}
                />
              </div>
            </div>
          </section>
          <section className="certi-table">
            <div className="certi-table-title">
              <h4>Provider Information</h4>
            </div>
            <div className="certi-table-content">
              <div className="certi-table-row">
                <BasicInfoTable
                  title1={"Policyholder"}
                  content1={info.contractor_name}
                  title2={"Policyholder VAT"}
                  content2={info.contractor_number}
                />
                <BasicInfoTable
                  title1={"Provider"}
                  content1={"Toggle Insurance Inc."}
                  title2={"Registration No."}
                  content2={"2020700006"}
                />
              </div>
            </div>
          </section>
          <section className="certi-table">
            <InsuredPersonInfo noinsd_list={info.noinsd_list} type="en" />
          </section>
          <section className="certi-table">
            <div className="certi-table-title">
              <h4>Beneficiary Information</h4>
            </div>
            <div className="certi-table-content">
              <div className="certi-table-row">
                <BasicInfoTable
                  title1={"Beneficiary"}
                  content1={"Oneself"}
                  title2={"Payable On Death"}
                  content2={"Legal Heir-at-Law"}
                />
              </div>
            </div>
          </section>
          <section className="certi-table warranty">
            <div className="certi-table-title">
              <h4>Coverages</h4>
            </div>
            <div className="certi-table-content">
              <Warranty cvrg_list={cvrg_list} type="en" />
            </div>
          </section>
          {/* <section className="certi-table">
            <Notice prctn_desctn={info.prctn_desctn} />
          </section> */}
          <section className="certi-table">
            <div className="certi-table-title">
              <h4>Verification</h4>
            </div>
            <div>
              <div>
                <BasicInfoTable
                  title1={"Agency"}
                  content1={"Toggle Insurance Inc."}
                  title2={"Published Date"}
                  content2={getDate()}
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default BasicInfoEn;
