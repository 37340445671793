import "./topbar.css";
import { itemKey, getItem, removeItem } from "../../libs/preference";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/toggle-logo-web-main.svg";

interface Props {
  admin_name: string;
  copncom_name: string;
}
export default function Topbar({ admin_name, copncom_name }: Props) {
  let token = getItem(itemKey.token);

  const topArea = () => {
    return (
      <div className="topbar">
        <div className="topbarWrapper">
          <div className="topLeft">
            <Link to="/">
              <img src={Logo} className="logo" alt="로고" />
            </Link>
          </div>
          <div className="topRight">
            <div className="topbarIconContainer">
              {"[" + copncom_name + "]"}
            </div>
            <div className="topbarIconContainer">{"[" + admin_name + "]"}</div>
            <div className="topbarIconContainer">담당자님 환영합니다.</div>
            <button
              className="logout-btn"
              onClick={() => {
                removeItem(itemKey.token);
                window.location.href = "/";
              }}
            >
              나가기
            </button>
          </div>
        </div>
      </div>
    );
  };
  return <>{token && topArea()}</>;
}
