import React, { Dispatch, SetStateAction } from "react";
import styles from "../../pages/cooperateregister/cooperregister.module.css";

interface Props {
  agree: boolean;
  setAgree: Dispatch<SetStateAction<boolean>>;
}
const CheckBox = ({ agree, setAgree }: Props) => {
  return (
    <div className={styles.form_agree}>
      <div className={styles.checkbox}>
        <input
          style={{ height: 15, width: 15 }}
          type="checkbox"
          id={"cooper-register-checkbox"}
          checked={agree}
          onChange={() => setAgree(!agree)}
        />
        <label className={styles.agreeTitle} htmlFor="cooper-register-checkbox">
          개인정보 수집 및 이용 동의
        </label>
      </div>
      <div className={styles.agreeMent}>
        파트너 신청 후 2영업일 이내 토글 팀에서 연락드려 사용 안내를 드립니다.
      </div>
    </div>
  );
};

export default CheckBox;
