import React from "react";
import styles from "../step.module.css";
interface Props {
  title: string;
  closeModal: any;
}
const Title = ({ title, closeModal = "" }: Props) => {
  return (
    <div className={styles.titleContainer}>
      <h2>{title}</h2>
      {closeModal && <button onClick={closeModal}>닫기</button>}
    </div>
  );
};

export default Title;
