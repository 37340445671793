import React from "react";
import styles from "./journeyInsurInfo.module.css";
interface Props {
  entry_people: string;
  obj: string;
  ins_start_day: string;
  ins_end_day: string;
  insucomr_name: string;
  prod_name: string;
}
const JourneyInsurInfo = ({
  entry_people,
  obj,
  ins_start_day,
  ins_end_day,
  insucomr_name,
  prod_name,
}: Props) => {
  return (
    <div className={styles.container}>
      <table className={styles.tableContainer}>
        <tr className={styles.tableTitle}>
          <td>보험사명</td>
          <td>{insucomr_name}</td>
          <td>상품명</td>
          <td>{prod_name}</td>
          <td>여행지역</td>
          <td>{obj}</td>
          <td>여행인원</td>
          <td>{entry_people}명</td>
        </tr>
        <tr className={styles.tableBody}>
          <td>출발일</td>
          <td>{ins_start_day.split(" ")[0]}</td>
          <td>출발시간</td>
          <td>{ins_start_day.split(" ")[1]}시</td>
          <td>도착일</td>
          <td>{ins_end_day.split(" ")[0]}</td>
          <td>도착시간</td>
          <td>{ins_end_day.split(" ")[1]}시</td>
        </tr>
      </table>
    </div>
  );
};

export default JourneyInsurInfo;
