import React, { useState } from "react";
import styles from "./pwdModal.module.css";
import Modal from "react-modal";
import TableTwoCol from "../../common/table/tableTwoCol/TableTwoCol";
import { reqPost } from "../../../libs/requests";
import api from "../../../libs/api";
interface Props {
  modal: boolean;
  onToggle: () => void;
}

const PwdModal = ({ modal, onToggle }: Props) => {
  const [pwd, setPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [newPwdChk, setNewPwdChk] = useState("");
  const [error, setError] = useState("");

  const onSubmit = () => {
    if (window.confirm("비밀번호를 변경 하시겠습니까?")) {
      const sendData = { original_pwd: pwd, new_pwd: newPwd };
      reqPost(api.pwdc, sendData, (result: any) => {
        if (result.data.resultinfo.result_div_cd === "OK") {
          alert("비밀번호가 변경되었습니다.");
        } else {
          alert("비밀번호 변경에 실패하였습니다.");
        }
        onToggle();
      });
    }
  };

  const pwdValiator = () => {
    if (pwd.length === 0) {
      setError("비밀번호를 입력해 주세요.");
      return;
    } else if (newPwd !== newPwdChk) {
      setNewPwd("");
      setNewPwdChk("");
      setError("비밀번호 확인이 일치하지 않습니다.");
      return;
    } else {
      setError("");
      onSubmit();
    }
  };

  return (
    <Modal
      onRequestClose={onToggle}
      isOpen={modal}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "#00000033",
        },
        content: {
          position: "absolute",
          overflow: "auto",
          outline: "none",
          width: "740px",
          height: "360px",
          margin: "auto",
          borderRadius: "8px",
          padding: 0,
        },
      }}
    >
      <div className={styles.modalInner}>
        <section className={styles.modalHeader}>
          <h2>비밀번호 변경</h2>
          <button onClick={onToggle}>닫기</button>
        </section>
        <section className={styles.modalMain}>
          <table className={styles.modalTable}>
            <TableTwoCol
              title={
                <div>
                  기존 비밀번호 <span className={styles.error}>*</span>
                </div>
              }
              content={
                <input
                  type={"password"}
                  className={styles.modalInput}
                  value={pwd}
                  onChange={(e) => setPwd(e.target.value)}
                />
              }
            />
            <TableTwoCol
              title={
                <div>
                  변경할 비밀번호 <span className={styles.error}>*</span>
                </div>
              }
              content={
                <input
                  type={"password"}
                  className={styles.modalInput}
                  value={newPwd}
                  onChange={(e) => setNewPwd(e.target.value)}
                />
              }
            />
            <TableTwoCol
              title={
                <div>
                  변경할 비밀번호 확인 <span className={styles.error}>*</span>
                </div>
              }
              content={
                <input
                  type={"password"}
                  className={styles.modalInput}
                  value={newPwdChk}
                  onChange={(e) => setNewPwdChk(e.target.value)}
                />
              }
            />
          </table>
          {/* <div className={styles.modalExtra}>
            <div>
              * 동일한 문자열 (예 : aaa, 111 등)을 3번 이상 연속해서 사용할 수
              없습니다.
            </div>
            <div>
              * 연속되는 문자열 (예:abc, 123 등)을 3자 이상 사용할 수 없습니다.
            </div>
          </div> */}
          <div className={styles.modalError}>
            <h4 className={styles.error}>{error}</h4>
          </div>
        </section>
        <section className={styles.modalBottom}>
          <button onClick={() => pwdValiator()}>변경</button>
        </section>
      </div>
    </Modal>
  );
};

export default PwdModal;
