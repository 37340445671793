import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers/";
import api from "../../../libs/api";
import { reqPost } from "../../../libs/requests";
import InsuredPeopleInfo from "./step6/InsuredPeopleInfo";
import JourneyInsurInfo from "./step6/JourneyInsurInfo";
import Title from "./common/Title";
import Payment from "./step6/Payment";
import styles from "./step.module.css";

type Props = {
  nextStep: () => void;
  closeModal: () => void;
};
const Step6 = ({ nextStep, closeModal }: Props) => {
  const { conct_number } = useSelector(
    (state: RootState) => state.journeyRegister
  );
  const title = "■ 해외여행보험가입";
  const title1 = "■ STEP6. 보험료 결제";
  const title2 = "여행자보험 정보";
  const title3 = "피보험자 정보";
  const [entry_people, setEntry_people] = useState<string>("");
  const [obj, setObj] = useState<string>("");
  const [ins_start_day, setIns_start_day] = useState<string>("");
  const [ins_end_day, setIns_end_day] = useState<string>("");
  const [insucomr_name, setInsucomr_name] = useState<string>("");
  const [prod_name, setProd_name] = useState<string>("");
  const [insurer_list, setInsurer_list] = useState([]);
  const [tot_prmim, setTot_prmim] = useState<string>("");
  const [member_sl_iprd_reg_if, setMember_sl_iprd_reg_if] =
    useState<string>("");

  const getContInfo = () => {
    const sendData = {
      conct_number: conct_number,
    };

    reqPost(api.continfo, sendData, (result: any) => {
      const item = result.data.d;
      setMember_sl_iprd_reg_if(item.member_sl_iprd_reg_if);
      setEntry_people(item.entry_people);
      setObj(item.obj);
      setIns_start_day(item.ins_start_day);
      setIns_end_day(item.ins_end_day);
      setInsucomr_name(item.insucomr_name);
      setProd_name(item.prod_name);
      setInsurer_list(item.insurer_list);
      setTot_prmim(item.tot_prmim);
    });
  };

  useEffect(() => {
    getContInfo();
  }, []);
  return (
    <div className={styles.container}>
      <Title title={title} closeModal={closeModal} />
      <div className={styles.inner}>
        <span className={styles.title}>{title1}</span>
        <p className={styles.description}>아래 정보를 확인 후 결제해 주세요.</p>
        <div className={styles.tableSection}>
          <span className={styles.title}>{title2}</span>
          <JourneyInsurInfo
            entry_people={entry_people}
            obj={obj}
            ins_start_day={ins_start_day}
            ins_end_day={ins_end_day}
            insucomr_name={insucomr_name}
            prod_name={prod_name}
          />
        </div>
        <div className={styles.tableSection}>
          <span className={styles.title}>{title3}</span>
          <InsuredPeopleInfo
            conct_number={conct_number}
            insurer_list={insurer_list}
            tot_prmim={tot_prmim}
          />
        </div>
        <div className={styles.tableSection}>
          <Payment
            member_sl_iprd_reg_if={member_sl_iprd_reg_if}
            tot_prmim={tot_prmim}
            nextStep={nextStep}
          />
        </div>
      </div>
    </div>
  );
};

export default Step6;
