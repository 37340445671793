import TableTwoCol from "../../common/table/tableTwoCol/TableTwoCol";
import "../../../pages/user/userMng/userMng.css";

interface Props {
  bankInfo: {
    bank_name: string;
    banK_number: string;
  };
}

const BankInfo = ({ bankInfo }: Props) => {
  return (
    <section className="user-mng-table">
      <div className="user-mng-table-title">
        <h2>지급 계좌 정보</h2>
      </div>
      <table className="user-mng-table-main">
        <TableTwoCol title={"은행명"} content={bankInfo.bank_name} />
        <TableTwoCol title={"계좌번호"} content={bankInfo.banK_number} />
      </table>
    </section>
  );
};

export default BankInfo;
