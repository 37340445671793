import { reqPost } from "../../libs/requests";
import { useEffect, useState } from "react";
import styles from "./step1.module.css";
import api from "../../libs/api";
import Item from "antd/lib/list/Item";

interface Props {
  sl_prod_number: string;
}

const Step1 = ({ sl_prod_number }: Props) => {
  const [insurInfo, setInsurInfo] = useState<any>([]);
  const [insurFtur, setInsurFtur] = useState<any>([]);
  const [destn, setDestn] = useState<Array<string>>([]);
  const [files, setFiles] = useState<Array<string>>([]);

  const getData = () => {
    const sendData = {
      sl_prod_number: sl_prod_number,
    };

    reqPost(api.prodes, sendData, (result: any) => {
      setInsurInfo(result.data.d);
      setInsurFtur(result.data.d.ftur);
      setFiles(result.data.d.file);
      setDestn(result.data.d.destn);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={styles.container}>
      <h1>상품설명</h1>
      <em className={styles.stressFont}>보험료 안내사항</em>
      <div>{insurInfo.guide_mttrs}</div>
      <table className={styles.tableWidth}>
        <tbody>
          <tr>
            <td className={styles.firstCol}>납입기간 : </td>
            <td className={styles.secondCol}>{insurInfo.receipt_term}</td>
          </tr>
          <tr>
            <td className={styles.firstCol}>보험기간 : </td>
            <td className={styles.secondCol}>{insurInfo.ins_term}</td>
          </tr>
          <tr>
            <td className={styles.firstCol}>상품종류 : </td>
            <td className={styles.secondCol}>{insurInfo.prod_kind}</td>
          </tr>
          <tr>
            <td className={styles.firstCol}>가입연령 : </td>
            <td className={styles.secondCol}>{insurInfo.entry_age}</td>
          </tr>
        </tbody>
      </table>
      <div style={{ marginTop: 30 }}>
        <em className={styles.stressTitleFont}>상품특징</em>

        <div className={styles.insurfeature}>
          {insurFtur.length > 0 &&
            insurFtur.map((item: any) => (
              <div>
                <div>
                  <img src={item.prod_ftur_img} />
                </div>
                <div>{item.prod_futr_desctn}</div>
              </div>
            ))}
        </div>
        <div>
          <div className={styles.container}>
            <h2 className={styles.stressTitleFont}>보험약관 및 상품설명서</h2>
            <em className={styles.stressFont}>
              본 내용은 요약된 내용이므로 자세한 사항은 반드시 아래 약관과
              설명서를 참조해주세요.
            </em>
            <div className={styles.twoBtn}>
              {files.map((file: any) => (
                <button
                  onClick={() => {
                    window.open(file.sl_data_file);
                  }}
                >
                  {file.sl_data_div_name}
                </button>
              ))}
            </div>
          </div>
          <div className={styles.container}>
            <div className={styles.stressTitleFont}>상품설명</div>
          </div>
          {destn.map((item: any) => (
            <div>
              <div className={styles.mainTitle}>{item.main_destn}</div>
              <div className={styles.stressFont1}>{item.sub_destn}</div>
            </div>
          ))}
        </div>
        <div className={styles.container}>
          {insurInfo.advert_delibtn_number}
        </div>
      </div>
    </div>
  );
};

export default Step1;
