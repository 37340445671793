import React from "react";
import styles from "./listTable.module.css";

interface Props {
  tr: any;
}

const ListTable = ({ tr }: Props) => {
  return (
    <table className={styles.listTableContainer}>
      <thead className={styles.listTableThead}>
        <tr className={styles.listTableTr}>
          <th>계약번호</th>
          {/*  */}
          <th>제휴사 명</th>
          <th>보험상품분류</th>
          <th>보험종류</th>
          <th>보험사명</th>
          <th>판매상품명</th>
          <th>보험시작일</th>
          <th>보험종료일</th>
          <th>가입 고객명</th>
          <th>가입인원</th>
          <th>결제금액</th>
          <th>상태</th>
          <th>가입일</th>
          {/* <th>가입취소일</th> */}
        </tr>
      </thead>
      <tbody className={styles.listTableTbody}>{tr}</tbody>
    </table>
  );
};

export default ListTable;
