import React from "react";
import "./title.css";

interface Props {
  title: string;
}

const Title = ({ title }: Props) => {
  return <div className="header-container">{title}</div>;
};

export default Title;
