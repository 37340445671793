import React from "react";
import styles from "./tableDiffColor.module.css";

interface Props {
  th: string[];
  td: any;
  align: any;
}
const TableDiffColor = ({ th, td, align = "center" }: Props) => {
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          {th.map((item, index) => (
            <th
              style={{ textAlign: align }}
              key={index}
              className={styles.thread}
            >
              {item}
            </th>
          ))}
        </tr>
      </thead>
      {align === "center" ? (
        <tbody className={styles.centerTable}>{td}</tbody>
      ) : (
        <tbody className={styles.leftTable}>{td}</tbody>
      )}
    </table>
  );
};

export default TableDiffColor;
