import styles from "../style/basicInfoTable.module.css";

interface Props {
  title1: string;
  content1: string;
  title2: string;
  content2: string;
}
const BasicInfoTable = ({ title1, content1, title2, content2 }: Props) => {
  return (
    <div className={styles.container}>
      <span className={styles.td}>{title1}</span>
      <span className={styles.td}>{content1}</span>
      <span className={styles.td}>{title2}</span>
      <span className={styles.td}>{content2}</span>
    </div>
  );
};

export default BasicInfoTable;
