import BasicInfo from "./basicInfo/BasicInfoEn";
import Modal from "react-modal";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
  },
};
interface Props {
  match: any;
}
const Index = ({ match }: Props) => {
  return (
    <div>
      <Modal isOpen={true} style={customStyles}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              width: "21cm",
              height: "29.7cm",
            }}
          >
            <BasicInfo paramId={match.params.id} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Index;
