import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { reqPost } from "../../libs/requests";
import api from "../../libs/api";
import "./contract.css";
import Title from "../../components/common/title/Title";
import ConstractDetailInfo from "../../components/constract/constractDetailInfo/ConstractDetailInfo";
import InsurCal from "../../components/constract/insurCal/InsurCal";
import InsurInfo from "../../components/constract/insurInfo/InsurInfo";
import PaymentInfo from "../../components/constract/paymentInfo/PaymentInfo";

type Payment_Info = {
  payment_amount: number;
  payment_cancelday: string;
  payment_day: string;
  payment_mean: string;
  payment_state_name: string;
  pmtagency_name: string;
  reg_seq: number;
};

const Contract = ({}) => {
  const { id }: any = useParams();
  const [payment_info, setPayment_info] = useState<Payment_Info[]>([]);
  const [insurer_list, setInsurer_list] = useState([]);
  const [amount, setAmount] = useState({
    tot_prmim: 0,
    tot_cpn_dc_amount: 0,
    tot_dc_amount: 0,
    tot_point_dc_amount: 0,
    payment_amount: 0,
  });
  const [constractDetailInfo, setConstractDetailInfo] = useState({
    conct_number: "",
    ins_kind_name: "",
    iprd_name: "",
    insurcom_name: "",
    prod_name: "",
    plangrd_name: "",
    obj: "",
    member_sl_iprd_state_name: "",
    entryday: "",
    entry_cancel_day: "",
    ins_start_day: "",
    ins_end_day: "",
    tot_prmim: 0,
  });

  const getContract = () => {
    const sendData = {
      conct_number: id,
    };
    reqPost(api.ctinfo, sendData, (result: any) => {
      const data = result.data.d;
      setConstractDetailInfo({
        conct_number: data.conct_number,
        ins_kind_name: data.ins_kind_name,
        iprd_name: data.iprd_name,
        insurcom_name: data.insurcom_name,
        prod_name: data.prod_name,
        plangrd_name: data.plangrd_name,
        obj: data.obj,
        member_sl_iprd_state_name: data.member_sl_iprd_state_name,
        entryday: data.entryday,
        entry_cancel_day: data.entry_cancel_day,
        ins_start_day: data.ins_start_day,
        ins_end_day: data.ins_end_day,
        tot_prmim: data.tot_prmim,
      });
      setInsurer_list(data.insurer_list);
      setPayment_info(data.payment_info);
      setAmount({
        tot_prmim: data.tot_prmim,
        tot_cpn_dc_amount: data.tot_cpn_dc_amount,
        tot_dc_amount: data.tot_dc_amount,
        tot_point_dc_amount: data.tot_point_dc_amount,
        payment_amount: data.payment_amount,
      });
    });
  };

  const goToKr = () => {
    window.sessionStorage.setItem("appbarnotexist", "true");
    window.open(
      `/contract/membershipkr/${constractDetailInfo.conct_number}`,
      "_blank"
    );
  };
  const goToEn = () => {
    window.open(
      `/contract/membershipen/${constractDetailInfo.conct_number}`,
      "_blank"
    );
  };
  const goToInvoice = () => {
    window.open("/contract/invoice/" + id, "_blank");
  };

  const handlePaymentCancel = () => {
    if (window.confirm("결제를 취소하시겠습니까?")) {
      const sendData = {
        conct_number: id,
      };
      reqPost(api.paymentCancel, sendData, (result: any) => {
        if (result.data.resultinfo.result_div_cd === "OK") {
          alert("결제취소가 완료되었습니다.");
          getContract();
        } else {
          alert("결제취소를 실패하였습니다.");
        }
      });
    }
  };

  useEffect(() => {
    getContract();
  }, []);

  return (
    <div className="constract-container">
      <Title title="계약 관리" />
      <div className="constract-background">
        <ConstractDetailInfo constractDetailInfo={constractDetailInfo} />
        <InsurInfo insurInfo={insurer_list} setInsurer_list={setInsurer_list} />
        <InsurCal amount={amount} />
        <PaymentInfo
          paymentInfo={payment_info}
          handlePaymentCancel={handlePaymentCancel}
        />
        <section className="constract-btn-wrapper">
          <button onClick={() => goToInvoice()}>인보이스</button>
          <button onClick={() => goToKr()}>국문 가입증명서</button>
          <button onClick={() => goToEn()}>영문 가입증명서</button>
        </section>
      </div>
    </div>
  );
};

export default Contract;
