import { ChangeEvent, useCallback, useState } from "react";
import { ensure } from "../libs/libcollect";

type Props = [File | undefined, (e: ChangeEvent) => void];

const useInputFile = (initialValue: File | undefined): Props => {
  const [input, setInput] = useState(initialValue);

  const handler = useCallback((e) => {
    const files = ensure(e.currentTarget.files);
    setInput(files[0]);
  }, []);

  return [input, handler];
};

export default useInputFile;
