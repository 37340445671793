//YYMMDD로 형식변경
function convertYYMMDD(birth: string) {
  var date = new Date(birth);
  var year = date.getFullYear().toString();
  var month = ("0" + (1 + date.getMonth())).slice(-2);
  var day = ("0" + date.getDate()).slice(-2);

  return year.substring(2, 4) + month + day;
}
function convertMMDDYYYY(birth: string) {
  const year = birth.substring(0, 4);
  const month = birth.substring(4, 6);
  const day = birth.substring(6, 8);
  return month + "/" + day + "/" + year;
}
//만나이 구하기
//str 01/01/91
export const calcAge = (str: string, lastFirstNum: string) => {
  if (
    lastFirstNum === "1" ||
    lastFirstNum === "2" ||
    lastFirstNum === "5" ||
    lastFirstNum === "6"
  ) {
    const firstYear = "19";
    const yymmdd = firstYear + convertYYMMDD(str);
    const fyymmdd = convertMMDDYYYY(yymmdd);

    var birthday = new Date(fyymmdd);

    var today = new Date();
    var years = today.getFullYear() - birthday.getFullYear();

    // Reset birthday to the current year.
    birthday.setFullYear(today.getFullYear());

    // If the user's birthday has not occurred yet this year, subtract 1.
    if (today < birthday) {
      years--;
    }
    return years;
  } else {
    const firstYear = "20";
    const yymmdd = firstYear + convertYYMMDD(str);
    const fyymmdd = convertMMDDYYYY(yymmdd);

    var birthday = new Date(fyymmdd);

    var today = new Date();
    var years = today.getFullYear() - birthday.getFullYear();

    // Reset birthday to the current year.
    birthday.setFullYear(today.getFullYear());

    // If the user's birthday has not occurred yet this year, subtract 1.
    if (today < birthday) {
      years--;
    }
    if (years < 0 || isNaN(years)) {
      return alert("현재 년보다 클수없습니다");
    }
    return years;
  }
};
//보험나이 구하기
//insur 01/01/91
//str 01/01/91
export const calcInsurAge = (
  insur: string,
  birth: string,
  lastFirstNum: string
) => {
  if (
    lastFirstNum === "1" ||
    lastFirstNum === "2" ||
    lastFirstNum === "5" ||
    lastFirstNum === "6"
  ) {
    const firstYear = "19";

    const birthYymmdd = firstYear + convertYYMMDD(birth);
    const birthFyymmdd = convertMMDDYYYY(birthYymmdd);

    var insurday = new Date(insur);
    var birthday = new Date(birthFyymmdd);

    var today = new Date();
    var years = today.getFullYear() - birthday.getFullYear();
    var minusDate = insurday.getMonth() - birthday.getMonth();
    // Reset birthday to the current year.
    birthday.setFullYear(today.getFullYear());

    // If the user's birthday has not occurred yet this year, subtract 1.
    if (today < birthday) {
      years--;
    }
    if (minusDate > 6) {
      years += 1;
    }
    return years;
  } else {
    const firstYear = "20";

    const birthYymmdd = firstYear + convertYYMMDD(birth);
    const birthFyymmdd = convertMMDDYYYY(birthYymmdd);

    var insurday = new Date(insur);
    var birthday = new Date(birthFyymmdd);

    var today = new Date();
    var years = today.getFullYear() - birthday.getFullYear();
    var minusDate = insurday.getMonth() - birthday.getMonth();
    // Reset birthday to the current year.
    birthday.setFullYear(today.getFullYear());

    // If the user's birthday has not occurred yet this year, subtract 1.
    if (today < birthday) {
      years--;
    }
    if (minusDate > 6) {
      years += 1;
    }
    if (years < 0 || isNaN(years)) {
      return;
    }
    return years;
  }
};
