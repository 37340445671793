import "./journeyPeopleInfo.css";
import TableRow from "./table/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { ExcelRenderer } from "react-excel-renderer";
import { RootState } from "../../redux/reducers/";
import {
  removeTableDataList,
  addTableDataList,
  changeVal,
  changeTableDataList,
  AddInsuranceProductList,
} from "../../redux/reducers/journeyRegisterReducer";
import { calcAge, calcInsurAge } from "../../libs/age";
import Question from "../../assets/images/question-circle-solid.svg";
import { convertGender } from "../../libs/libcollect";
import ReactTooltip from "react-tooltip";

interface Props {
  title2: string;
  areaGubun: string;
}
const JourneyPeopleInfo = ({ title2, areaGubun }: Props) => {
  const dispatch = useDispatch();
  const {
    tableDataList,
    departTime,
    arriveTime,
    departDate,
    arriveDate,
    nation,
  } = useSelector((state: RootState) => state.journeyRegister);

  const deleteRow = (idx: number) => {
    dispatch(
      removeTableDataList({
        idx: idx,
      })
    );
    dispatch(
      AddInsuranceProductList({
        insurProductList: [],
      })
    );
  };
  const addRow = () => {
    dispatch(addTableDataList());
    //보험료 3단계 계산결과 초기화
    dispatch(
      AddInsuranceProductList({
        insurProductList: [],
      })
    );
  };
  const calcAgeFc = (regNumber: string, regLastNumber: string) => {
    const first = regNumber.substr(0, 2);
    const second = regNumber.substr(2, 2);
    const thrid = regNumber.substr(4, 2);

    const firstLastNumber = regLastNumber.substr(0, 1);

    const calcBeforeAge = second + "/" + thrid + "/" + first;
    const age = calcAge(calcBeforeAge, firstLastNumber);
    return age;
  };
  const calcInsurAgeFc = (regNumber: string, regLastNumber: string) => {
    const firstDepartDate = departDate.substr(0, 2);
    const secondDepartDate = departDate.substr(2, 2);
    const thridDepartDate = departDate.substr(4, 2);

    const first = regNumber.substr(0, 2);
    const second = regNumber.substr(2, 2);
    const thrid = regNumber.substr(4, 2);
    const calcBeforeAge = second + "/" + thrid + "/" + first;
    const calcBeforeDeartDate =
      secondDepartDate + "/" + thridDepartDate + "/" + firstDepartDate;

    const firstLastNumber = regLastNumber.substr(0, 1);

    return calcInsurAge(calcBeforeDeartDate, calcBeforeAge, firstLastNumber);
  };
  const saveFile = () => {
    window.open(
      "https://storage.googleapis.com/toggle-real-files/B2B/%E1%84%87%E1%85%A9%E1%84%92%E1%85%A5%E1%86%B7%E1%84%80%E1%85%A1%E1%84%8B%E1%85%B5%E1%86%B8%E1%84%8B%E1%85%A3%E1%86%BC%E1%84%89%E1%85%B5%E1%86%A8.xlsx"
    );
  };
  const addExcel = (e: any) => {
    dispatch(
      AddInsuranceProductList({
        insurProductList: [],
      })
    );
    dispatch(
      changeTableDataList({
        tableDataList: [],
      })
    );
    dispatch(addTableDataList());

    let fileObj = e.target.files[0];

    ExcelRenderer(fileObj, (err: any, resp: any) => {
      if (err) {
        alert(err);
      } else if (resp.rows[0][0] !== "한글성명*") {
        alert("엑셀 명단이 정확하지 않습니다.");
      } else {
        const testjsonDataList = new Array();
        let rows = resp.rows.filter((row: any[]) => row[0] !== undefined);
        rows.shift();
        rows = rows.filter((row: string[]) => Boolean(row.length));
        const excelRows: number = rows.length;
        dispatch(
          changeVal({
            departTime: departTime,
            arriveTime: arriveTime,
            departDate: departDate,
            arriveDate: arriveDate,
            journeyPeopleCnt: excelRows,
            nation: nation,
          })
        );
        rows.map((row: any, idx: number) => {
          const phoneNumber = row[3] ? row[3] : "";

          let obj1 = {
            idx: idx + 1,
            insurer_name: row[0],
            insurer_eng_name: row[1],
            regNumberFirst: row[2].split("-")[0],
            regNumberLast: row[2].split("-")[1],
            insurer_mphone_number: phoneNumber.split("-").join(""),
            age: calcAgeFc(row[2].split("-")[0], row[2].split("-")[1]),
            insu_age: calcInsurAgeFc(
              row[2].split("-")[0],
              row[2].split("-")[1]
            ),
            insurer_gender_code: convertGender(
              row[2].split("-")[1].substr(0, 1)
            ),
          };
          testjsonDataList.push(obj1);
        });
        dispatch(
          changeTableDataList({
            tableDataList: testjsonDataList,
          })
        );
      }
    });
    e.target.value = "";
  };
  return (
    <div className="insurPeople-form-table-container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: 27,
        }}
      >
        <span className="headText-title">{title2}</span>
        <div className="insurPeople-form-table-btn">
          <label className="input-file-button" htmlFor="input-file">
            엑셀 명단 업로드
          </label>
          <input
            type="file"
            id="input-file"
            onChange={addExcel}
            style={{ display: "none" }}
          />
          <label
            className="input-file-button"
            htmlFor="input-file"
            onClick={saveFile}
          >
            엑셀 양식 다운로드
          </label>
        </div>
      </div>
      <table className="insurPeople-form-table">
        <tr>
          <th className="insurPeople-form-table-th" style={{ width: "5%" }}>
            <span className="title-text">인원</span>
          </th>
          <th className="insurPeople-form-table-th">
            <span className="title-text">
              피보험자명<span className="require">*</span>
            </span>
          </th>
          {areaGubun === "IP20201202035154rjQR" && (
            <th className="insurPeople-form-table-th">
              <span className="title-text">
                피보험자명 (영문)<span className="require">*</span>
              </span>
            </th>
          )}
          <th className="insurPeople-form-table-th">
            <span className="title-text">
              주민번호<span className="require">*</span>
            </span>
          </th>
          <th className="insurPeople-form-table-th">
            <span className="title-text">
              휴대폰번호<span className="require">*</span>
            </span>
          </th>
          <th className="insurPeople-form-table-th">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span className="title-text">만나이</span>
              <img
                src={Question}
                style={{ marginBottom: 2, marginLeft: 10, width: 15 }}
                data-tip
                data-for="registerTip7"
                alt="물음표"
              />
              <ReactTooltip id="registerTip7" place="top" effect="solid">
                <div>
                  <em style={{ color: "red" }}>만나이 </em>: 보험가입시 만나이
                  기준으로 플랜에 가입됩니다.
                </div>
              </ReactTooltip>
            </div>
          </th>
          <th className="insurP eople-form-table-th">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span className="title-text">보험나이</span>
              <img
                src={Question}
                style={{ marginBottom: 2, marginLeft: 10, width: 15 }}
                data-tip
                data-for="registerTip8"
                alt="물음표"
              />
              <ReactTooltip id="registerTip8" place="top" effect="solid">
                <div>
                  <em style={{ color: "red" }}>보험나이 </em>: 보험가입시 보험료
                  계산 기준은 보험나이 기준으로 산정됩니다.
                </div>
              </ReactTooltip>
            </div>
          </th>
          <th style={{ width: "10%" }}>
            <span className="title-text">구분</span>
          </th>
        </tr>
        <tbody className="tableDataList-row">
          {tableDataList.map((d: any, i: number) => {
            return (
              <TableRow
                areaGubun={areaGubun}
                key={i}
                data={d}
                deleteRow={deleteRow}
              />
            );
          })}
        </tbody>
      </table>
      <div className="add-btn">
        <button onClick={addRow}>추가</button>
      </div>
    </div>
  );
};

export default JourneyPeopleInfo;
