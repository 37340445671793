import { useState, useEffect } from "react";
import { reqPost } from "../../../libs/requests";
import api from "../../../libs/api";
import Title from "../../../components/common/title/Title";
import Background from "../../../components/common/background/Background";
import "./userMng.css";
import PwdModal from "../../../components/userMng/pwdModal/PwdModal";
import BasicInfo from "../../../components/userMng/basicInfo/BasicInfo";
import UserInfo from "../../../components/userMng/userInfo/UserInfo";
import BankInfo from "../../../components/userMng/bankInfo/BankInfo";
import CooperateInfo from "../../../components/userMng/cooperateInfo/CooperateInfo";
import LoginHistoryInfo from "../../../components/userMng/loginHistoryInfo/LoginHistoryInfo";

const UserMng = () => {
  const [loginHistoryInfo, setLoginHistoryInfo] = useState([]);
  const [cooperateInfo, setCooperateInfo] = useState([]);
  const [modal, setModal] = useState<boolean>(false);
  const [basicInfo, setBasicInfo] = useState({
    copncom_name: "",
    bizregno: "",
    regday: "",
    email: "",
  });
  const [userInfo, setUserInfo] = useState({
    admin_name: "",
    officer_email: "",
    mphone_number: "",
  });
  const [bankInfo, setBankInfo] = useState({
    bank_name: "",
    banK_number: "",
  });
  const getData = () => {
    const sendData = {};
    reqPost(api.userInfo, sendData, (result: any) => {
      const data = result.data.d;
      setBasicInfo({
        copncom_name: data.copncom_name,
        bizregno: data.bizregno,
        regday: data.regday,
        email: data.email,
      });
      setUserInfo({
        admin_name: data.admin_name,
        officer_email: data.officer_email,
        mphone_number: data.mphone_number,
      });
      setBankInfo({
        bank_name: data.bank_name,
        banK_number: data.banK_number,
      });
      setCooperateInfo(data.copncom_info);
      setLoginHistoryInfo(data.login_history_info);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const onToggleModal = () => {
    setModal(!modal);
  };

  return (
    <div className="user-mng-container">
      <Title title={"사용자 정보"} />
      <Background>
        <div className="user-mng-padding">
          <div className="user-mng-width">
            <h2 className="user-mng-title">토글 서비스 제휴 정보</h2>
            <BasicInfo basicInfo={basicInfo} onToggle={onToggleModal} />
            <UserInfo userInfo={userInfo} />
            <BankInfo bankInfo={bankInfo} />
            <CooperateInfo cooperateInfo={cooperateInfo} />
            <LoginHistoryInfo loginHistoryInfo={loginHistoryInfo} />
          </div>
        </div>
      </Background>
      {modal && <PwdModal modal={modal} onToggle={onToggleModal} />}
    </div>
  );
};

export default UserMng;
