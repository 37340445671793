import { useEffect, useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import styles from "./cooperregister.module.css";
import { reqPost } from "../../libs/requests";
import api from "../../libs/api";
import Logo from "../../assets/images/toggle-logo-web-main.svg";
import { apiUrl } from "../../libs/consts";
import {
  ensure,
  validationBusinessNumber,
  validationEmail,
  validationLength,
} from "../../libs/libcollect";
import useInput from "../../hooks/useInput";
import useInputFile from "../../hooks/useInputFile";

import CorInput from "../../components/cooperreguster/CorInput";
import CorTitle from "../../components/cooperreguster/CorTitle";
import CorInputFile from "../../components/cooperreguster/CorInputFile";
import CheckBox from "../../components/cooperreguster/CheckBox";
import InputWrapper from "../../components/cooperreguster/InputWrapper";
import BankSelector from "../../components/cooperreguster/BankSelector";

type BankList = {
  bank_code: string;
  bank_name: string;
};

const CooperRegister = () => {
  let history = useHistory();

  const [co_name, onChangeCoName] = useInput({ title: "상호명", value: "" });
  const [bizNumber, onChangeBizNumber] = useInput({
    title: "사업자등록번호",
    value: "",
  });
  const [bizFile, onChangeBizFile] = useInputFile(undefined);
  const [bankName, onChangeBankName] = useInput({ title: "은행명", value: "" });
  const [bankNumer, onChangeBankNumber] = useInput({
    title: "계좌번호",
    value: "",
  });
  const [bankFile, onChangeBankFile] = useInputFile(undefined);
  const [holder, onChangeHolder] = useInput({ title: "계좌주", value: "" });
  const [regusr, onChangeRegusr] = useInput({ title: "담당자", value: "" });
  const [email, onChangeEmail] = useInput({ title: "이메일", value: "" });
  const [contt, onChangeContt] = useInput({
    title: "연락처",
    value: "",
  });
  const [agree, setAgree] = useState(false);
  const [bankList, setBankList] = useState<BankList[]>([
    { bank_code: "", bank_name: "선택" },
  ]);

  const handleSubmit = () => {
    const formData = new FormData();

    formData.append("coopco_name", co_name.value);
    formData.append("biz_reg_number", bizNumber.value);
    formData.append("bank_code", bankName.value);
    formData.append("bank_number", bankNumer.value);
    formData.append("bank_account_holder", holder.value);
    formData.append("officer_name", regusr.value);
    formData.append("officer_email", email.value);
    formData.append("officer_contt", contt.value);
    formData.append("biz_reg_number_copy_photo", ensure(bizFile));
    formData.append("bank_number_copy_photo", ensure(bankFile));

    if (window.confirm("파트너 신청을 하시겠습니까?")) {
      reqPost(api.coopapc, formData, (result: any) => {
        if (result.data.resultinfo.result_div_cd === "OK") {
          alert(
            `파트너 신청이 완료되었습니다.${"\n"}빠른 시일 내에 연락 드리겠습니다.${"\n"}문의사항(02-1661-4045)`
          );
          history.goBack();
        } else {
          alert(`파트너 신청이 실패하였습니다.${"\n"}문의사항(02-1661-4045)`);
        }
      });
    }
  };

  const getBankList = () => {
    reqPost(api.banklist, {}, (result: any) => {
      if (result.data.resultinfo.result_div_cd === "OK") {
        setBankList(bankList.concat(result.data.d));
      }
    });
  };

  useEffect(() => {
    getBankList();
  }, []);

  const chkValidation = () => {
    let chkValue = [
      co_name,
      bizNumber,
      bankName,
      bankNumer,
      holder,
      regusr,
      contt,
    ];
    for (let i = 0; i < chkValue.length; i++) {
      if (!validationLength(chkValue[i].value)) {
        alert(`${chkValue[i].title}을(를) 정확히 입력해 주세요`);
        return false;
      }
    }
    if (!validationBusinessNumber(bizNumber.value)) {
      alert(`${bizNumber.title}을 정확히 입력해 주세요`);
      return false;
    }
    if (!validationEmail(email.value)) {
      alert(`${email.title}을 정확히 입력해 주세요`);
      return false;
    }
    if (bizFile === undefined) {
      alert(`사업자등록증을 정확히 첨부해 주세요`);
      return false;
    }
    if (bankFile === undefined) {
      alert("통장사본을 정확히 첨부해 주세요");
      return false;
    }
    if (!agree) {
      alert("개인정보 수집 및 이용 동의에 체크해 주세요");
      return false;
    }
    handleSubmit();
  };

  return (
    <div className={styles.container}>
      <div className={styles.form_container}>
        <div className={styles.form}>
          <div className={styles.LogoImg}>
            <img src={Logo} className={styles.logo} alt="로고" />
            <div>{apiUrl.indexOf("dev") !== -1 && "개발서버"}</div>
          </div>
          <div className={styles.header_title}>
            <h3 className={styles.formTitle}>파트너 신청</h3>
          </div>
          <div className={styles.header_explain}>
            B2B여행자보험예약시스템으로 파트너 신청 후 보험가입이 가능하며,
            <br />
            파트너 신청 시 영업일기준 2일이내 토글 팀에서 <br />
            사용 안내 연락 드립니다.
          </div>
          <InputWrapper>
            <Fragment>
              <CorTitle title="상호명" />
              <CorInput
                value={co_name.value}
                onChange={onChangeCoName}
                placeholder={"상호명을 입력하세요"}
                type={"text"}
              />
            </Fragment>
          </InputWrapper>
          <InputWrapper>
            <Fragment>
              <CorTitle
                title={"사업자등록번호(사업자등록증 사본을 첨부하여 주세요)"}
              />
              <CorInput
                type="number"
                placeholder="- 없이 입력해 주세요"
                value={bizNumber.value}
                onChange={onChangeBizNumber}
              />
              <CorInputFile
                id={"biz"}
                file={bizFile}
                onChange={onChangeBizFile}
              />
            </Fragment>
          </InputWrapper>
          <InputWrapper>
            <Fragment>
              <CorTitle title={"은행 / 계좌번호"} />
              <BankSelector
                onChangeBankName={onChangeBankName}
                bankList={bankList}
                bankNumer={bankNumer}
                onChangeBankNumber={onChangeBankNumber}
              />
              <CorInputFile
                id={"bank"}
                file={bankFile}
                onChange={onChangeBankFile}
              />
            </Fragment>
          </InputWrapper>
          <InputWrapper>
            <Fragment>
              <CorTitle title={"통장 계좌주 명칭"} />
              <CorInput
                value={holder.value}
                onChange={onChangeHolder}
                placeholder={"통장 계좌주 명칭을 입력하세요"}
                type={"text"}
              />
            </Fragment>
          </InputWrapper>
          <InputWrapper>
            <Fragment>
              <CorTitle title={"제휴 담당자 성명"} />
              <CorInput
                value={regusr.value}
                onChange={onChangeRegusr}
                placeholder={"제휴 담당자 성명을 입력하세요"}
                type={"text"}
              />
            </Fragment>
          </InputWrapper>
          <InputWrapper>
            <Fragment>
              <CorTitle title={"제휴 담당자 이메일 주소"} />
              <CorInput
                value={email.value}
                onChange={onChangeEmail}
                placeholder={"제휴 담당자 이메일 주소를 입력하세요"}
                type={"text"}
              />
            </Fragment>
          </InputWrapper>
          <InputWrapper>
            <Fragment>
              <CorTitle title={"제휴 담당자 연락처"} />
              <CorInput
                value={contt.value}
                onChange={onChangeContt}
                placeholder={"- 없이 입력해 주세요"}
                type={"number"}
              />
            </Fragment>
          </InputWrapper>
          <CheckBox agree={agree} setAgree={setAgree} />
          <div className={styles.form_partner_apply}>
            <button
              className={styles.form_partner_apply_btn}
              onClick={chkValidation}
            >
              파트너 신청
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CooperRegister;
