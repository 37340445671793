import { useState } from "react";
import "./App.css";
import { reqPost } from "./libs/requests";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { itemKey, getItem } from "./libs/preference";
import api from "./libs/api";
import Sidebar from "./components/sidebar/Sidebar";
import UserList from "./pages/userList/UserList";
import Topbar from "./components/topbar/Topbar";
import SignIn from "./pages/signIn/SignIn";
import InsurProductForm from "./pages/insurproduct/InsurProductForm";
import PrivateRoute from "./components/route/PrivateRoute";
import Contract from "./pages/contract/Contract";
import Home from "./pages/home/Home";
import Invoice from "./pages/Invoice/Invoice";
import NoticeList from "./pages/notice/noticeList/NoticeList";
import UserMng from "./pages/user/userMng/UserMng";
import CooperRegister from "./pages/cooperateregister/CooperRegister";
import NoticeDetail from "./pages/notice/noticeDetail/NoticeDetail";
import First from "./components/membershipcerti/kor/Index";
import FirstEn from "./components/membershipcerti/en/Index";

import { useEffect } from "react";
function App() {
  let token = getItem(itemKey.token);
  const [admin_name, setAdmin_name] = useState<string>("");
  const [copncom_name, setCopncom_name] = useState<string>("");
  const getUserInfo = () => {
    const sendData = {};
    reqPost(api.info, sendData, (result: any) => {
      setAdmin_name(result.data.d.admin_name);
      setCopncom_name(result.data.d.copncom_name);
    });
  };

  useEffect(() => {
    getUserInfo();
  }, [token]);

  return (
    <Router>
      <Topbar admin_name={admin_name} copncom_name={copncom_name} />
      <div className="container">
        <Sidebar copncom_name={copncom_name} />
        <Switch>
          <Route exact path="/">
            {token ? (
              <PrivateRoute restricted={true} component={Home} path="/" exact />
            ) : (
              <SignIn />
            )}
          </Route>
          <Route path="/cooperate-register" component={CooperRegister}></Route>
          <PrivateRoute
            restricted={true}
            component={UserList}
            path="/contract-management"
            exact
          />
          <PrivateRoute
            restricted={true}
            component={InsurProductForm}
            path="/overseas-travel-in-surance/online-quote"
            exact
          />
          <PrivateRoute
            restricted={true}
            component={InsurProductForm}
            path="/domestic-travel-in-surance/online-quote"
            exact
          />
          <PrivateRoute
            restricted={true}
            component={Contract}
            path="/contract/contract-detail/:id"
            exact
          />
          <Route path="/contract/membershipkr/:id" component={First} />
          <Route path="/contract/membershipen/:id" component={FirstEn} />
          <PrivateRoute
            restricted={true}
            component={Invoice}
            path="/contract/invoice/:id"
            exact
          />
          <PrivateRoute
            restricted={true}
            component={NoticeList}
            path="/notice"
            exact
          />
          <PrivateRoute
            restricted={true}
            component={UserMng}
            path="/usermng"
            exact
          />
          <PrivateRoute
            restricted={true}
            component={NoticeDetail}
            path="/notice/:id"
            exact
          />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
