import { ChangeEvent } from "react";
import styles from "../../pages/cooperateregister/cooperregister.module.css";

interface Props {
  value: string;
  onChange: (e: ChangeEvent<Element>) => void;
  placeholder: string;
  type: string;
}

const CorInput = ({ value, onChange, placeholder, type }: Props) => {
  return (
    <input
      className={styles.inputstyle}
      placeholder={placeholder}
      value={value}
      type={type}
      onChange={onChange}
    />
  );
};

export default CorInput;
