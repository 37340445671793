import React from "react";
import "./insurCal.css";
import { moneyComma } from "../../../libs/libcollect";

interface Props {
  amount: {
    tot_prmim: number;
    tot_cpn_dc_amount: number;
    tot_dc_amount: number;
    tot_point_dc_amount: number;
    payment_amount: number;
  };
}

const InsurCal = ({ amount }: Props) => {
  return (
    <section className="constract-inner">
      <h4 className="constract-title">■ 결제 보험료 계산</h4>
      <table className="insurcal-table">
        <tr>
          <td>총보험료</td>
          <td>{moneyComma(amount.tot_prmim)}</td>
          <td>총 할인금액</td>
          <td>{moneyComma(amount.tot_dc_amount)}</td>
          <td>쿠폰할인금액</td>
          <td>{moneyComma(amount.tot_cpn_dc_amount)}</td>
          <td>포인트 할인금액</td>
          <td>{moneyComma(amount.tot_point_dc_amount)}</td>
          <td>결제금액</td>
          <td>{moneyComma(amount.payment_amount)}</td>
        </tr>
      </table>
    </section>
  );
};

export default InsurCal;
