import "./home.css";
import Slider from "react-slick";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { reqPost } from "../../libs/requests";
import api from "../../libs/api";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import banner01 from "../../assets/images/banner01.png";
import banner_01 from "../../assets/images/banner_01.svg";
import banner_02 from "../../assets/images/banner_02.svg";
import banner_03 from "../../assets/images/banner_03.svg";
import banner_04 from "../../assets/images/banner_04.svg";

import TalkChannel from "../../assets/images/btn-ch.png";

type NoticeList = {
  content: string;
  noti_number: string;
  regday: string;
  title: string;
};

const slickData = [{ image: banner01 }];

const Main = () => {
  const history = useHistory();
  const [noticeList, setNoticeList] = useState<NoticeList[]>([]);
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    appendDots: (dots: any) => (
      <div
        style={{
          padding: "50px",
        }}
      >
        <ul style={{ margin: "0px" }}>{dots}</ul>
      </div>
    ),
  };

  const getData = () => {
    const sendData = {};
    reqPost(api.noticelist, sendData, (result: any) => {
      if (result.data.resultinfo.result_div_cd === "OK") {
        setNoticeList(result.data.d);
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="main-container">
      <section className="main-slider">
        <div className="main-contents">
          <div className="main-banner">
            <div className="main-banner-title">
              <h2>토글 여행 보험 B2B 시스템 서비스 소개</h2>
            </div>
            <div className="main-banner-flex">
              <div>
                <div className="main-banner-img-wrapper">
                  <img src={banner_01} alt="banner_01" />
                </div>
                <h6>
                  간편한 여행 보험
                  <br />
                  가격 비교
                </h6>
              </div>
              <div>
                <div className="main-banner-img-wrapper">
                  <img src={banner_02} alt="banner_02" />
                </div>
                <h6>
                  최대 150명 일괄
                  <br />
                  간편단체 가입
                </h6>
              </div>
              <div>
                <div className="main-banner-img-wrapper">
                  <img src={banner_03} alt="banner_03" />
                </div>
                <h6>
                  국문 증명서 및 영문 증명서
                  <br />
                  즉시 발급
                </h6>
              </div>
              <div>
                <div className="main-banner-img-wrapper">
                  <img src={banner_04} alt="banner_04" />
                </div>

                <h6>
                  개인카드/법인카드
                  <br />
                  모두 결제 가능
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="main-under">
        <section className="main-notice">
          <div className="main-contents">
            <h3 className="main-content-title">공지사항</h3>
            <div className="main-content-inner">
              {noticeList.map((item, index) => (
                <div
                  key={index}
                  className="main-notice-content"
                  onClick={() => history.push(`/notice/${item.noti_number}`)}
                >
                  <div className="main-notice-title">
                    <h4>{item.title}</h4>
                    <span>{item.regday}</span>
                  </div>
                  <p className="main-notice-explain">{item.content}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="main-question">
          <div className="main-contents">
            <h3 className="main-content-title">문의하기</h3>
            <div className="main-content-inner">
              <div className="main-question-content">
                <h4>일반문의</h4>
                <div>CS@openyourplan.com</div>
              </div>
              <div className="main-question-content">
                <h4>전화문의</h4>
                <div>(10:00 ~ 17:00)</div>
                <div>1661-4045</div>
              </div>
              <div className="main-question-content">
                <h4>카톡문의</h4>
                <a href="http://pf.kakao.com/_UmKfK">
                  <img src={TalkChannel} title="토글 카카오톡 채널" />
                </a>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};

export default Main;
