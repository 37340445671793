import { moneyComma } from "../../../libs/libcollect";
import "../style/warranty.css";

type Cvrg = {
  covge_exposure_amount: string;
  covge_exposure_amount_name: string;
  iprd_covge_name: string;
};
type CvrgList = {
  age_covge_name: string;
  covge_age_end: string;
  covge_age_start: string;
  Cvrg: Cvrg[];
};
interface Props {
  cvrg_list: CvrgList[];
  type: string;
}

const Warranty = ({ cvrg_list, type }: Props) => {
  return (
    <div className={"warranty-container"}>
      {type === "ko" ? (
        <ul className={"warranty-title"}>
          <li>플랜명</li>
          <li>담보명</li>
          <li>가입금액</li>
        </ul>
      ) : (
        <ul className={"warranty-title"}>
          <li>Name of Plan</li>
          <li>Coverages</li>
          <li>Insured Amount</li>
        </ul>
      )}
      <div className={"warranty-body-container"}>
        {cvrg_list.map((item: CvrgList, index) => (
          <ul key={index} className={"warranty-body"}>
            <li className={"warranty-body-title"}>
              {item.age_covge_name}
              <br />
              {item.covge_age_start}~{item.covge_age_end}
              {type === "ko" && "세"}
            </li>
            <li key={index} className={"warranty-body-content"}>
              {item.Cvrg.map((item2: Cvrg, index: number) => (
                <div key={index}>
                  <div className={"warranty-body-content-name"}>
                    {item2.iprd_covge_name}
                  </div>
                  <div className={"warranty-body-content-price"}>
                    {type === "ko"
                      ? `${item2.covge_exposure_amount}${item2.covge_exposure_amount_name}`
                      : `KRW ${moneyComma(item2.covge_exposure_amount)}`}
                  </div>
                </div>
              ))}
            </li>
          </ul>
        ))}
      </div>
    </div>
  );
};

export default Warranty;
