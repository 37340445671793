import { useState } from "react";
import { useHistory } from "react-router-dom";
import "./signIn.css";
import Logo from "../../assets/images/toggle-logo-web-main.svg";
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

import { reqPost } from "../../libs/requests";
import api from "../../libs/api";
import { setItem, itemKey, encrypt } from "../../libs/preference";
import { Link } from "react-router-dom";

interface LoginData {
  login_if: string;
  login_paswd: string;
  imp_uid?: string;
}

const SignIn = () => {
  const userCode = "imp16952560";
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  /* 본인인증 후 콜백함수 */
  function callback(response: any) {
    const sendData = {
      login_if: email,
      login_paswd: encrypt(password),
      imp_uid: response.imp_uid,
    };
    if (response.success) {
      requestLogin(sendData);
    } else {
      alert(response.error_msg);
    }
  }

  const enterEnable = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onLogin();
    }
  };

  const onLogin = () => {
    const sendData = {
      login_if: email,
      login_paswd: encrypt(password),
    };

    if (email === "test@openyourplan.com") {
      requestLogin(sendData);
    } else {
      const { IMP }: any = window;
      IMP.init(userCode);
      IMP.certification(sendData, callback);
    }
  };

  const requestLogin = (sendData: LoginData) => {
    reqPost(api.login, sendData, (result: any) => {
      if (
        result.data.d !== "" ||
        result.data.resultinfo.result_div_cd === "OK"
      ) {
        let d = result.data.d;
        setItem(itemKey.token, d.admin_token);
        setItem(itemKey.name, d.admin_name);
        setItem(itemKey.agency_name, d.agency_name);
        history.push(`/`);
        window.location.reload();
      } else {
        alert("아이디 또는 비밀번호가 틀렸습니다.");
      }
    });
  };
  return (
    <div className="background">
      <div className="form">
        <div className="form-contents">
          <div className="form-logo">
            <img src={Logo} />
          </div>
          <div className="signin-form-title">
            <div>든든한 하루보험의 시작</div>
            <h2>토글 하루보험</h2>
            <hr className="signin-form-divider" />
            <div>관리자 로그인</div>
          </div>
          <div className="form-login-area">
            <div className="signin-input">
              <div className="signin-input-title">이메일 주소</div>
              <Input
                id="outlined-required"
                placeholder="example@openyourplan.com"
                onChange={(e) => setEmail(e.target.value)}
                style={{ width: 250, height: 45, borderRadius: 8 }}
              />
            </div>
            <div className="signin-input">
              <div className="signin-input-title">
                <div>비밀번호</div>
              </div>
              <Input.Password
                id="outlined-required"
                placeholder="input password"
                onChange={(e) => setPassword(e.target.value)}
                style={{ width: 250, height: 50, borderRadius: 10 }}
                onKeyPress={enterEnable}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </div>
            <button className="signin-login-btn" onClick={onLogin}>
              로그인하기
            </button>
            <div>
              <div>
                제휴 파트너 회원 가입을 원하세요 ?
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#ff6f64",
                    fontSize: 13,
                  }}
                >
                  <Link to="/cooperate-register">회원가입</Link>
                </a>
              </div>
              <div
                style={{
                  marginTop: 10,
                  color: "#ff6f64",
                  fontSize: 13,
                }}
              >
                E-mail / Password 변경을 원하시면
                <br />
                토글로 문의하여 주십시요 (02-1661-4045)
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
