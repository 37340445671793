import { useState, useEffect } from "react";
import "./payment.css";
import PaymentForm from "./PaymentForm";
import { reqPost } from "../../../../libs/requests";
import api from "../../../../libs/api";
import Loading from "../../../../components/common/Loding";
import styles from "../step.module.css";

const crypto = require("crypto");
interface Props {
  member_sl_iprd_reg_if: string;
  tot_prmim: string;
  nextStep: () => void;
}

const Payment = ({ member_sl_iprd_reg_if, tot_prmim, nextStep }: Props) => {
  const [changeCardMethod, setChangeCardMethod] = useState<boolean>(false);
  const [birth, setBirth] = useState<string>("");
  const [card_quota, setCard_quota] = useState<string>("1");
  const [cardNumber1, setCardNumber1] = useState<string>("");
  const [cardNumber2, setCardNumber2] = useState<string>("");
  const [cardNumber3, setCardNumber3] = useState<string>("");
  const [cardNumber4, setCardNumber4] = useState<string>("");
  const [YYYY, setYYYY] = useState<string>("");
  const [MM, setMM] = useState<string>("");
  const [creditcard_paswd, setCreditcard_paswd] = useState<string>("");
  const [publicKey, setPublicKey] = useState<string>("");
  let [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    reqPost(api.pem, "", (result: any) => {
      setPublicKey(result.data);
    });
  }, []);

  function stringToHex(str: string) {
    //converting string into buffer
    let bufStr = Buffer.from(str, "utf8");

    //with buffer, you can convert it into hex with following code
    return bufStr.toString("hex");
  }

  const Pay = () => {
    setLoading(true);
    const sendData = {
      card_quota: card_quota,
      creditcard_reg_number:
        cardNumber1 + "-" + cardNumber2 + "-" + cardNumber3 + "-" + cardNumber4,
      creditcard_validterm: YYYY + "-" + MM,
      creditcard_paswd: creditcard_paswd,
      birth: birth,
      payment_amount: tot_prmim,
      member_sl_iprd_reg_if: member_sl_iprd_reg_if,
    };
    const encryptedData = crypto.publicEncrypt(
      {
        key: publicKey,
        padding: crypto.constants.RSA_PKCS1_PADDING,
      },
      // We convert the data string to a buffer using `Buffer.from`
      Buffer.from(JSON.stringify(sendData))
    );
    const base64Data = encryptedData.toString("base64");
    const hexedData = stringToHex(base64Data);

    const finalSendData = {
      rcd: hexedData,
    };

    reqPost(api.payment, finalSendData, (result: any) => {
      if (result.data.resultinfo.result_div_cd === "OK") {
        ///다음화면
        nextStep();
      } else {
        alert(result.data.resultinfo.result_cd);
      }
      setLoading(false);
    });
  };
  return (
    <div>
      <div>
        <button
          className={
            changeCardMethod ? "payment-btn-unselected" : "payment-btn-selected"
          }
          onClick={() => setChangeCardMethod(false)}
        >
          개인
        </button>
        <button
          className={
            changeCardMethod ? "payment-btn-selected" : "payment-btn-unselected"
          }
          onClick={() => setChangeCardMethod(true)}
        >
          법인
        </button>
      </div>
      <PaymentForm
        changeCardMethod={changeCardMethod}
        setBirth={setBirth}
        setCard_quota={setCard_quota}
        setCardNumber1={setCardNumber1}
        setCardNumber2={setCardNumber2}
        setCardNumber3={setCardNumber3}
        setCardNumber4={setCardNumber4}
        setYYYY={setYYYY}
        setMM={setMM}
        setCreditcard_paswd={setCreditcard_paswd}
      />
      {loading && <Loading />}
      <div className={styles.bottomBtn}>
        <button onClick={Pay}>결제하기</button>
      </div>
    </div>
  );
};

export default Payment;
