import React, { ChangeEvent } from "react";
import styles from "../../pages/cooperateregister/cooperregister.module.css";

type BankList = {
  bank_code: string;
  bank_name: string;
};

interface Props {
  onChangeBankName: (e: ChangeEvent<Element>) => void;
  bankList: BankList[];
  bankNumer: { title: string; value: string };
  onChangeBankNumber: (e: ChangeEvent<Element>) => void;
}
const BankSelector = ({
  onChangeBankName,
  bankList,
  bankNumer,
  onChangeBankNumber,
}: Props) => {
  return (
    <div className={styles.fileWrapper}>
      <select className={styles.bankSelector} onChange={onChangeBankName}>
        {bankList.map((item: BankList) => (
          <option value={item.bank_code} key={item.bank_code}>
            {item.bank_name}
          </option>
        ))}
      </select>
      <input
        className={styles.bankNumber}
        type="number"
        placeholder="- 없이 입력해 주세요"
        value={bankNumer.value}
        onChange={onChangeBankNumber}
      />
    </div>
  );
};

export default BankSelector;
