import React from "react";
import { Route, Redirect } from "react-router-dom";
import { itemKey, getItem } from "../../libs/preference";

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  let token = getItem(itemKey.token);
  if (token === null || token === undefined || token === "") {
    window.location.href = "/";
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        token ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default PrivateRoute;
