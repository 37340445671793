import { useCallback, useEffect } from "react";
import "./userList.css";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { Select, Input, DatePicker, Pagination } from "antd";
import { reqPost } from "../../libs/requests";
import { moneyComma } from "../../libs/libcollect";
import api from "../../libs/api";
import Title from "../../components/common/title/Title";
import ListTable from "../../components/userList/ListTable";
const { RangePicker } = DatePicker;
const { Option } = Select;

type contractProps = {
  conct_number: string;
  entry_cancelday: string;
  entry_custm_name: string;
  entry_people: number;
  entryday: string;
  ins_end_day: string;
  ins_kind_name: string;
  ins_start_day: string;
  insurcom_name: string;
  payment_amount: number;
  coopco_name: string;
  iprd_name: string;
  member_sl_iprd_reg_if: string;
  member_sl_iprd_state_name: string;
  plangrd_name: string;
  prod_name: string;
  tot_prmim: number;
};

type PageInfoProps = {
  limit: number;
  nextpage: number;
  offset: number;
  page: number;
  prevpage: number;
  totalpage: number;
  totalrecord: number;
};
export default function UserList() {
  const history = useHistory();
  // const [data, setData] = useState(userRows);
  const [contracts, setContracts] = useState<contractProps[]>([]);
  const [page_info, setPage_info] = useState<PageInfoProps>();
  const [pageNo, setPageNo] = useState(1);
  const [entry_custm_name, setEntry_custm_name] = useState<string>("");
  const [code_list, setCode_list] = useState([]);
  const [conct_number, setConct_number] = useState("");
  const [start_day, setStart_day] = useState("");
  const [end_day, setEnd_day] = useState("");
  const [term_div_code, setTerm_div_code] = useState("");
  const [member_sl_iprd_state_code, setMember_sl_iprd_state_code] =
    useState<string>("");

  const getContract = useCallback(
    (page_no: number) => {
      const sendData = {
        entry_custm_name: entry_custm_name,
        conct_number: conct_number,
        member_sl_iprd_state_code: member_sl_iprd_state_code,
        insurcom_reg_if: "",
        term_div_code: term_div_code,
        start_day: start_day,
        end_day: end_day,
        page_no: page_no,
      };

      reqPost(api.ctlist, sendData, (result: any) => {
        const data = result.data.d;
        setContracts(data.list);
        setPage_info(data.page_info);
        setCode_list(data.code_list);
      });
    },
    [
      conct_number,
      end_day,
      entry_custm_name,
      member_sl_iprd_state_code,
      start_day,
      term_div_code,
    ]
  );

  const returnCodeInfo = (com_cd_grp_id: any) => {
    let codeList: any = code_list.find((obj: any) => {
      return obj.com_cd_grp_id === com_cd_grp_id;
    });
    return Boolean(codeList) ? codeList.com_cd_list : [];
  };

  const changePage = (page_no: number) => {
    setPageNo(page_no);
  };

  const handleInputChange = (value: string) => {
    setMember_sl_iprd_state_code(value);
  };

  const handleDateChange = (value: string) => {
    setTerm_div_code(value);
  };

  useEffect(() => {
    getContract(pageNo);
  }, [pageNo]);

  const listItem = () => {
    return contracts.map((item, index) => (
      <tr
        key={index}
        onClick={() =>
          history.push(`/contract/contract-detail/${item.conct_number}`)
        }
      >
        <td>{item.conct_number}</td>
        <td>{item.coopco_name}</td>
        <td>{item.plangrd_name}</td>
        <td>{item.ins_kind_name}</td>
        <td>{item.insurcom_name}</td>
        <td>{item.prod_name}</td>
        <td>{item.ins_start_day}</td>
        <td>{item.ins_end_day}</td>
        <td>{item.entry_custm_name}</td>
        <td>{item.entry_people}</td>
        <td>{moneyComma(item.payment_amount)}</td>
        <td>{item.member_sl_iprd_state_name}</td>
        <td>{item.entryday}</td>
        {/* <td>{item.entry_cancelday}</td> */}
      </tr>
    ));
  };

  return (
    <div className="user-list-container">
      <Title title="계약리스트" />
      <div className="user-list-background">
        <section className="user-list-inner">
          <table className="user-list-table">
            <tr className="user-list-header-table">
              <td>고객명</td>
              <td>
                <Input
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEntry_custm_name(e.target.value)
                  }
                />
              </td>
              <td>계약번호</td>
              <td>
                <Input
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setConct_number(e.target.value)
                  }
                />
              </td>
              <td>계약상태</td>
              <td className="user-list-input-button">
                <div>
                  <Select
                    defaultValue={"전체"}
                    onChange={(value: string) => handleInputChange(value)}
                    style={{ width: "60%" }}
                  >
                    <Option value={""}>{"전체"}</Option>
                    {returnCodeInfo("IP024").map((item: any, index: number) => (
                      <Option key={index} value={item.com_cd}>
                        {item.com_cd_nm}
                      </Option>
                    ))}
                  </Select>
                  <span className="user-list-button">
                    <button onClick={() => getContract(pageNo)}>조회</button>
                  </span>
                </div>
              </td>
            </tr>
            {/*  */}
            <tr>
              <td>기간선택</td>
              <td>
                <Select
                  defaultValue={"전체"}
                  onChange={(value: string) => handleDateChange(value)}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Option value={""}>{"전체"}</Option>
                  {returnCodeInfo("CM032").map((item: any, index: number) => (
                    <Option key={index} value={item.com_cd}>
                      {item.com_cd_nm}
                    </Option>
                  ))}
                  {/* <Option value={"2"}>{"계약완료"}</Option>
              <Option value={"3"}>{"계약종료"}</Option>
              <Option value={"4"}>{"계약취소"}</Option> */}
                </Select>
              </td>
              <td>날짜선택</td>
              <td colSpan={4}>
                <RangePicker
                  onChange={(val: any, val2: any) => {
                    setStart_day(val2[0]);
                    setEnd_day(val2[1]);
                  }}
                />
              </td>
            </tr>
          </table>
        </section>
        <section className="user-list-inner">
          <ListTable tr={listItem()} />
          <div style={{ marginTop: 20 }}>
            {page_info && (
              <Pagination
                showSizeChanger
                defaultCurrent={pageNo}
                total={Number(`${page_info.totalpage}0`)}
                onChange={(page_no: number) => changePage(page_no)}
              />
            )}
          </div>
        </section>
      </div>
    </div>
  );
}
