import React from "react";
import "./background.css";

interface Props {
  children: JSX.Element;
}

const Background = ({ children }: Props) => {
  return (
    <div className="background-container">
      <div className="background-content">{children}</div>
    </div>
  );
};

export default Background;
