import { useEffect, useState } from "react";
import Modal from "react-modal";
import "./invoice.css";
import { reqPost } from "../../libs/requests";
import api from "../../libs/api";
import { moneyComma } from "../../libs/libcollect";
import Logo from "../../assets/images/toggle-logo-web-main.svg";
import { useParams } from "react-router-dom";
import TableDiffColor from "../../components/common/table/tableDiffColor/TableDiffColor";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
  },
};
const Invoice = ({}) => {
  const params: { id: string } = useParams();
  const [invoice, setInvoice] = useState<any>([]);

  const getData = () => {
    const sendData = {
      conct_number: params.id,
    };
    reqPost(api.invoice, sendData, (result: any) => {
      setInvoice(result.data.d);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const listItem = () => {
    return (
      <tr style={{ textAlign: "center" }}>
        <td>{invoice.copncom_name}</td>
        <td>
          {invoice.insu_start_day} ~ {invoice.issue_date}
        </td>
        <td>{invoice.prod_name}</td>
        <td>{invoice.plangrd_name}</td>
        <td>{invoice.entry_people}</td>
        <td>{moneyComma(Number(invoice.total_prmim))}</td>
      </tr>
    );
  };

  return (
    <div>
      <Modal isOpen={true} style={customStyles}>
        <div className="invoice-container">
          <section className="invoice-logo">
            <div className="invoice-inner">
              <img src={Logo} alt="로고" />
            </div>
          </section>
          <section className="invoice-company-info">
            <div className="invoice-inner">
              <h2 className="invoice-title">오픈플랜 주식회사</h2>
              <h3>서울시 서초구 신반포로 315,삼덕빌딩 2층</h3>
              <h3>TEL: 1661-4045</h3>
            </div>
          </section>
          <section className="invoice-date">
            <div className="invoice-inner">
              <h2 className="invoice-title">INVOICE</h2>
              <h3>수신 : {invoice.copncom_name}</h3>
              <h3>발행일시 : {invoice.issue_date}</h3>
            </div>
          </section>
          <section className="invoice-guide">
            <div className="invoice-inner">
              <h2>
                여행자보험 가입건에 대한 인보이스를 보내드리오니
                <br />
                확인하시고 결재하여 주시기 바랍니다.
              </h2>
            </div>
          </section>
          <section className="invoice-table">
            <TableDiffColor
              th={[
                "단체",
                "기간",
                "상품명",
                "상품플랜",
                "인원",
                "보험료(한화)",
              ]}
              td={listItem()}
              align="center"
            />
          </section>
        </div>
      </Modal>
    </div>
  );
};

export default Invoice;
