import TableDiffColor from "../../common/table/tableDiffColor/TableDiffColor";
import "../../../pages/user/userMng/userMng.css";

interface Props {
  cooperateInfo: {
    CommRt: number;
    copn_conn_state_name: string;
    ins_kind_name: string;
    iprd_class_name: string;
    iprd_name: string;
    vat_incl_fg: string;
    regday: string;
  }[];
}

const CooperateInfo = ({ cooperateInfo }: Props) => {
  const listItem = () => {
    return cooperateInfo.map((item, index) => (
      <tr key={index}>
        <td>{item.ins_kind_name}</td>
        <td>{item.iprd_class_name}</td>
        <td>{item.iprd_name}</td>
        <td>{item.CommRt + "%"}</td>
        <td>{item.vat_incl_fg}</td>
        <td>{item.copn_conn_state_name}</td>
        <td>{item.regday}</td>
      </tr>
    ));
  };

  return (
    <section className="user-mng-table">
      <div className="user-mng-table-title">
        <h2>제휴정보</h2>
      </div>
      <TableDiffColor
        th={[
          "보험종류",
          "보험상품분류",
          "보험상품명",
          "수수료율",
          "VAT포함여부",
          "연결상태",
          "등록일",
        ]}
        td={listItem()}
        align="center"
      />
    </section>
  );
};

export default CooperateInfo;
