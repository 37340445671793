import { useState, useEffect } from "react";
import "./journeyInfo.css";

import { Select } from "antd";
import "antd/dist/antd.css";
import { DatePicker } from "antd";
import moment from "moment";
import { InputNumber } from "antd";
import { insurTime, getFormatDate } from "../../libs/time";
import { useDispatch, useSelector } from "react-redux";
import {
  changeNationCode,
  changeVal,
  AddInsuranceProductList,
} from "../../redux/reducers/journeyRegisterReducer";
import { RootState } from "../../redux/reducers/";
import { reqPost } from "../../libs/requests";
import api from "../../libs/api";
import Question from "../../assets/images/question-circle-solid.svg";
import ReactTooltip from "react-tooltip";

const dateFormat = "YYYY-MM-DD";
const { Option } = Select;
interface Props {
  handlePeople: (cnt: number) => void;
  areaGubun: string;
}

const JourneyInfo = ({ handlePeople, areaGubun }: Props) => {
  const dispatch = useDispatch();
  const {
    departTime,
    arriveTime,
    departDate,
    arriveDate,
    journeyPeopleCnt,
    nation,
  } = useSelector((state: RootState) => state.journeyRegister);
  const [nationList, setNationList] = useState<any>([]);

  const getNation = () => {
    const sendData = {};
    reqPost(api.overseas, sendData, (result: any) => {
      setNationList(result.data.d);
    });
  };
  useEffect(() => {
    getNation();
  }, []);
  const onChangeDepartDate = (date: any, dateString: any) => {
    const date1 = new Date();
    const date2 = new Date(dateString);
    const arriveDate = new Date(dateString);
    arriveDate.setDate(arriveDate.getDate() + 1);

    const formatArriveDate = getFormatDate(arriveDate);
    if (date1 > date2) {
      return alert("출발일은 오늘보다 작을 수 없습니다.");
    }

    dispatch(
      changeVal({
        departTime: departTime,
        arriveTime: arriveTime,
        departDate: dateString,
        arriveDate: formatArriveDate,
        journeyPeopleCnt: journeyPeopleCnt,
        nation: nation,
      })
    );
    dispatch(
      AddInsuranceProductList({
        insurProductList: [],
      })
    );
  };
  const onChangeArriveDate = (date: any, dateString: any) => {
    let date1 = new Date(departDate);
    let date2 = new Date(dateString);
    if (date1 > date2) {
      return alert("도착일은 출발일보다 작을 수 없습니다.");
    }
    const diff = (date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24);

    if (areaGubun === "IP20201202035154rjQR") {
      if (diff > 90) {
        return alert("최대 90일까지 선택 가능합니다");
      }
    } else {
      if (diff > 30) {
        return alert("최대 30일까지 선택 가능합니다");
      }
    }
    //보험료 3단계 계산결과 초기화

    dispatch(
      changeVal({
        departTime: departTime,
        arriveTime: arriveTime,
        departDate: departDate,
        arriveDate: dateString,
        journeyPeopleCnt: journeyPeopleCnt,
        nation: nation,
      })
    );
    dispatch(
      AddInsuranceProductList({
        insurProductList: [],
      })
    );
  };
  const handleChange = (value: string, e: any) => {
    dispatch(
      changeNationCode({
        nationCode: e.name,
      })
    );
    dispatch(
      changeVal({
        departTime: departTime,
        arriveTime: arriveTime,
        departDate: departDate,
        arriveDate: arriveDate,
        journeyPeopleCnt: journeyPeopleCnt,
        nation: value,
      })
    );
    dispatch(
      AddInsuranceProductList({
        insurProductList: [],
      })
    );
  };
  function addDays(myDate: Date, days: number) {
    return new Date(myDate.getTime() + days * 24 * 60 * 60 * 1000);
  }

  useEffect(() => {
    var myDate = new Date(departDate);

    var newDate = addDays(myDate, 1);
    const addOneDay = getFormatDate(newDate);
    dispatch(
      changeVal({
        departTime: departTime,
        arriveTime: arriveTime,
        departDate: departDate,
        arriveDate: addOneDay,
        journeyPeopleCnt: journeyPeopleCnt,
        nation: nation,
      })
    );
  }, []);

  return (
    <div>
      <table className="insur-form-table">
        {areaGubun === "IP20201202035154rjQR" && (
          <tr>
            <td className="insur-form-table-inner-first">
              <span style={{ display: "flex" }}>
                <span className="title-text">여행지역</span>{" "}
                <span className="require">*</span>
                <div>
                  <img
                    src={Question}
                    style={{ marginBottom: 2, marginLeft: 10, width: 15 }}
                    data-tip
                    data-for="registerTip"
                  />
                </div>
                <ReactTooltip id="registerTip" place="top" effect="solid">
                  <div>
                    다수의 국가를 여행하시는 경우,{" "}
                    <em style={{ color: "red" }}>
                      하나의 대표여행 국가를 선택해주세요.
                    </em>
                    <br /> 국가별 보험료 차이가 없습니다. <br />
                  </div>
                </ReactTooltip>
              </span>{" "}
            </td>
            <td className="insur-form-table-inner-second">
              <Select
                defaultValue={"선택해주세요"}
                style={{
                  minWidth: 120,
                  width: "90%",
                }}
                onChange={handleChange}
              >
                {nationList.map((item: any, idx: number) => (
                  <Option
                    key={idx}
                    value={item.country_name}
                    name={item.country_code}
                  >
                    {item.country_name}
                  </Option>
                ))}
              </Select>
            </td>
            <td className="insur-form-table-inner-first"></td>
            <td className="insur-form-table-inner-second"></td>
          </tr>
        )}

        <tr>
          <td className="insur-form-table-inner-first">
            <div style={{ display: "flex" }}>
              <span className="title-text">출발일</span>
              <span className="require">*</span>
              <div>
                <img
                  src={Question}
                  style={{ marginBottom: 2, marginLeft: 10, width: 15 }}
                  data-tip
                  data-for="registerTip2"
                />
              </div>
              <ReactTooltip id="registerTip2" place="top" effect="solid">
                <div>
                  <em style={{ color: "red" }}>
                    시작 일시부터 종료 일시까지 보장되며,아래 기준으로 선택해
                    주세요.{" "}
                  </em>{" "}
                  <br />
                  <em style={{ color: "red" }}>시작 일시 </em>
                  : 여행을 위해 집에서 출발하는 일시(이미 출발했을 경우 비행기
                  탑승 일시) <br />
                  <em style={{ color: "red" }}>
                    최대 {areaGubun === "IP20201202035154rjQR" ? "90" : "30"}일
                  </em>
                  까지 선택 가능합니다.
                </div>
              </ReactTooltip>
            </div>
          </td>
          <td className="insur-form-table-inner-second">
            <DatePicker
              allowClear={false}
              defaultValue={moment(new Date(), dateFormat)}
              value={moment(departDate, dateFormat)}
              format={dateFormat}
              style={{ width: "90%" }}
              onChange={onChangeDepartDate}
              disabledDate={(current) => {
                let customDate = moment().format("YYYY-MM-DD");

                const futureMonthLimit = moment()
                  .add(60, "d")
                  .format("YYYY-MM-DD");

                return (
                  (current && current < moment(customDate, "YYYY-MM-DD")) ||
                  current > moment(futureMonthLimit, "YYYY-MM-DD")
                );
              }}
            />
          </td>
          <td className="insur-form-table-inner-first">
            <div style={{ display: "flex" }}>
              <span className="title-text">출발시간</span>
              <span className="require">*</span>
              <img
                src={Question}
                style={{ marginBottom: 2, marginLeft: 10, width: 15 }}
                data-tip
                data-for="registerTip4"
              />
            </div>

            <ReactTooltip id="registerTip4" place="top" effect="solid">
              <div>
                <em style={{ color: "red" }}>
                  시작 일시부터 종료 일시까지 보장되며,아래 기준으로 선택해
                  주세요.
                </em>
                <br />
                <em style={{ color: "red" }}>시작 일시 </em>
                : 여행을 위해 집에서 출발하는 일시(이미 출발했을 경우 비행기
                탑승 일시) <br />
                <em style={{ color: "red" }}>
                  최대 {areaGubun === "IP20201202035154rjQR" ? "90" : "30"}일
                </em>
                까지 선택 가능합니다.
              </div>
            </ReactTooltip>
          </td>
          <td className="insur-form-table-inner-second">
            <Select
              defaultValue={departTime}
              style={{ minWidth: 120, width: "90%" }}
              value={departTime}
              onChange={(time: string) => {
                const dtA = new Date(departDate + " " + time);
                const dtB = new Date(arriveDate + " " + arriveTime);

                if (dtA > dtB) {
                  alert("도착시간은 출발시간보다 빠를 수 없습니다");
                  return;
                }
                dispatch(
                  changeVal({
                    departTime: time,
                    arriveTime: arriveTime,
                    departDate: departDate,
                    arriveDate: arriveDate,
                    journeyPeopleCnt: journeyPeopleCnt,
                    nation: nation,
                  })
                );
                dispatch(
                  AddInsuranceProductList({
                    insurProductList: [],
                  })
                );
              }}
            >
              {insurTime.map((item, idx) => (
                <Option key={idx} value={item.time}>
                  {item.time}
                </Option>
              ))}
            </Select>
          </td>
        </tr>
        <tr>
          <td className="insur-form-table-inner-first">
            <div style={{ display: "flex" }}>
              <span className="title-text">도착일</span>
              <span className="require">*</span>
              <div>
                <img
                  src={Question}
                  style={{ marginBottom: 2, marginLeft: 10, width: 15 }}
                  data-tip
                  data-for="registerTip3"
                />
              </div>
              <ReactTooltip id="registerTip3" place="top" effect="solid">
                <div>
                  <em style={{ color: "red" }}>종료 일시</em> : 여행을 마치고
                  집에 도착하는 일시
                </div>
              </ReactTooltip>
            </div>
          </td>
          <td className="insur-form-table-inner-second">
            <DatePicker
              allowClear={false}
              defaultValue={moment(new Date(), dateFormat)}
              value={moment(arriveDate, dateFormat)}
              format={dateFormat}
              style={{ width: "90%" }}
              onChange={onChangeArriveDate}
              disabledDate={(current) => {
                let customDate = moment().add(1, "days").format("YYYY-MM-DD");
                return current && current < moment(customDate, "YYYY-MM-DD");
              }}
            />
          </td>
          <td className="insur-form-table-inner-first">
            <div style={{ display: "flex" }}>
              <span className="title-text">도착시간</span>
              <span className="require">*</span>
              <img
                src={Question}
                style={{ marginBottom: 2, marginLeft: 10, width: 15 }}
                data-tip
                data-for="registerTip5"
              />
            </div>
            <ReactTooltip id="registerTip5" place="top" effect="solid">
              <div>
                <em style={{ color: "red" }}>종료 일시</em> : 여행을 마치고 집에
                도착하는 일시
              </div>
            </ReactTooltip>
          </td>
          <td className="insur-form-table-inner-second">
            <Select
              defaultValue={arriveTime}
              value={arriveTime}
              style={{ minWidth: 120, width: "90%" }}
              onChange={(time: string) => {
                const dtA = new Date(departDate + " " + departTime);
                const dtB = new Date(arriveDate + " " + time);

                if (dtA > dtB) {
                  alert("도착시간은 출발시간보다 빠를 수 없습니다");
                  return;
                }
                dispatch(
                  changeVal({
                    departTime: departTime,
                    arriveTime: time,
                    departDate: departDate,
                    arriveDate: arriveDate,
                    journeyPeopleCnt: journeyPeopleCnt,
                    nation: nation,
                  })
                );
                dispatch(
                  AddInsuranceProductList({
                    insurProductList: [],
                  })
                );
              }}
            >
              {insurTime.map((item, idx) => (
                <Option key={idx} value={item.time}>
                  {item.time}
                </Option>
              ))}
            </Select>
          </td>
        </tr>
        <tr>
          <td className="insur-form-table-inner-first">
            <div style={{ display: "flex" }}>
              <span className="title-text">여행인원</span>
              <span className="require">*</span>
            </div>
          </td>
          <td className="insur-form-table-inner-second">
            <InputNumber
              onChange={(value): any => handlePeople(value)}
              keyboard={true}
              defaultValue={0}
              value={journeyPeopleCnt}
              style={{ width: "90%" }}
              min={0}
            />
          </td>
          <td className="insur-form-table-inner-first"></td>
          <td className="insur-form-table-inner-second"></td>
        </tr>
      </table>
    </div>
  );
};

export default JourneyInfo;
