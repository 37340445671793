// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login: "/login/",
  primim: "/insu/prod/primim",
  noticelist: "/main/noticelist", //메인 공지사항
  overseas: "/insu/destination/overseas", //국가조회
  overseascheck: "/insu/destination/overseas/check", //해외국가가능여부조회
  underwriting: "/insu/underwriting", //알릴사항
  stpl: "/insu/stpl", //가입약관
  provisionalreg: "/insu/provisionalreg", //결제전 보험데이터 생성
  continfo: "/insu/continfo", //결제페이지 조회
  ctlist: "/insu/ctlist", //계약목록 조회
  ctinfo: "/insu/ctinfo", //계약상세정보
  engnamec: "/insu/ctinfo/engnamec", //계약영문명 등록
  payment: "/insu/payment", //결제요청
  paymentCancel: "/insu/payment/cancel", //결제 취소
  certificate: "/insu/ctinfo/certificate", //국문가입증명서
  engcertificate: "/insu/ctinfo/engcertificate", //국문가입증명서영문
  pem: "/it/pem", //펨키
  invoice: "/insu/ctinfo/invoice",
  info: "/login/info",
  prodes: "/insu/prodes", //상품설명조회
  cvrginfo: "/insu/cvrginfo",
  needknow: "/insu/needknow",
  userInfo: "/user/info",
  notiList: "/noti/list",
  notiinfo: "/noti/info",
  coopapc: "/login/coopapc",
  pwdc: "/user/pwdc",
  banklist: "/login/banklist", // 은행, 보험사 리스트
  recommendlist: "/login/recommendlist", // 추천사 리스트
  coopmainbizlist: "/login/coopmainbizlist", // 주 업무 구분 리스트
};
