import React from "react";
import TableDiffColor from "../../common/table/tableDiffColor/TableDiffColor";
import { Input } from "antd";
import { moneyComma, ensure } from "../../../libs/libcollect";
import { reqPost } from "../../../libs/requests";
import api from "../../../libs/api";

type InsurerList = {
  age: number;
  individ_prmim: number;
  insu_age: number;
  insurer_eng_name: string;
  insurer_gender_name: string;
  insurer_mphone_number: string;
  insurer_name: string;
  insurer_res_reg_no: string;
  member_sl_iprd_reg_if: string;
  reg_seq: number;
};
interface Props {
  insurInfo: InsurerList[];
  setInsurer_list: any;
}

const InsurInfo = ({ insurInfo, setInsurer_list }: Props) => {
  const handleChgEngName = (
    e: React.ChangeEvent<HTMLInputElement>,
    values: InsurerList
  ) => {
    const res = ensure(
      insurInfo.find((item) => item.reg_seq === values.reg_seq)
    );

    res.insurer_eng_name = e.target.value
      .toUpperCase()
      .replace(/[^A-Za-z]/gi, "")
      .replace(" ", "");
    setInsurer_list([...insurInfo]);
  };

  const handleSave = () => {
    const res: { eng_name: string; reg_seq: number }[] = insurInfo.map(
      (item) => ({
        eng_name: item.insurer_eng_name,
        reg_seq: item.reg_seq,
      })
    );
    const sendData = {
      member_sl_iprd_reg_if: insurInfo[0].member_sl_iprd_reg_if,
      insurer_list: res,
    };
    reqPost(api.engnamec, sendData, (result: any) => {
      if (result.data.resultinfo.result_div_cd === "OK") {
        alert("변경되었습니다.");
      } else {
        alert("변경에 실패하였습니다.");
      }
    });
  };

  const listItem = () => {
    return insurInfo.map((item, index) => (
      <tr key={index}>
        <td>{item.insurer_name}</td>
        <td>
          <Input
            type="text"
            onChange={(e) => handleChgEngName(e, item)}
            value={item.insurer_eng_name}
          />
        </td>
        <td>{item.age}</td>
        <td>{item.insu_age}</td>
        <td>{item.insurer_gender_name}</td>
        <td>{item.insurer_res_reg_no}</td>
        <td>{item.insurer_mphone_number}</td>
        <td>{moneyComma(item.individ_prmim)}</td>
      </tr>
    ));
  };

  return (
    <section className="constract-inner">
      <h4 className="constract-title">■ 피보험자 정보</h4>
      <TableDiffColor
        th={[
          "피보험자 성명",
          "영문성명",
          "(만) 나이",
          "(보험) 나이",
          "성별",
          "주민등록번호",
          "휴대폰번호",
          "개별 보험료",
        ]}
        td={listItem()}
        align="center"
      />
      <div className="constract-btn-wrapper">
        <button onClick={handleSave} className="btn-save">
          저장
        </button>
      </div>
    </section>
  );
};

export default InsurInfo;
