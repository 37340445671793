import { Select, Input } from "antd";
import "./payment.css";
import { useRef } from "react";

const { Option } = Select;

const inputStyle = {
  width: "20%",
  marginLeft: "20px",
  height: "70%",
};

interface Props {
  changeCardMethod: any;
  setBirth: any;
  setCard_quota: any;
  setCardNumber1: any;
  setCardNumber2: any;
  setCardNumber3: any;
  setCardNumber4: any;
  setYYYY: any;
  setMM: any;
  setCreditcard_paswd: any;
}
const PaymentForm = ({
  changeCardMethod,
  setBirth,
  setCard_quota,
  setCardNumber1,
  setCardNumber2,
  setCardNumber3,
  setCardNumber4,
  setYYYY,
  setMM,
  setCreditcard_paswd,
}: Props) => {
  const cardInput2 = useRef<any>(null);
  const cardInput3 = useRef<any>(null);
  const cardInput4 = useRef<any>(null);
  const validDateInput1 = useRef<any>(null);
  const validDateInput2 = useRef<any>(null);
  const pwdInput1 = useRef<any>(null);
  const birthInput1 = useRef<any>(null);
  const businInput1 = useRef<any>(null);
  return (
    <div className="paymentform-container">
      <table className="paymentform-table">
        <colgroup>
          <col style={{ width: "10%" }} />
          <col style={{ width: "30%" }} />
        </colgroup>
        <tr className="table-two-col-container">
          <td style={{ textIndent: 10 }}>할부개월수</td>
          <td>
            <Select
              defaultValue={"0"}
              style={{
                width: "30%",
                display: "flex",
                justifyContent: "flex-start",
                marginLeft: "20px",
              }}
              onChange={(val) => setCard_quota(val)}
            >
              <Option value={"0"}>{"일시불"}</Option>
            </Select>
          </td>
        </tr>
        <tr className="table-two-col-container">
          <td style={{ textIndent: 10 }}>카드번호</td>
          <td
            style={{
              textAlign: "left",
            }}
          >
            <div>
              <Input
                style={inputStyle}
                onChange={(e) => {
                  setCardNumber1(e.target.value);
                  if (e.target.value.length === 4) {
                    cardInput2.current.focus();
                  }
                }}
                maxLength={4}
                id="cardinput1"
              />
              <Input
                style={inputStyle}
                onChange={(e) => {
                  setCardNumber2(e.target.value);
                  if (e.target.value.length === 4) {
                    cardInput3.current.focus();
                  }
                }}
                maxLength={4}
                ref={cardInput2}
              />
              <Input
                style={inputStyle}
                onChange={(e) => {
                  setCardNumber3(e.target.value);
                  if (e.target.value.length === 4) {
                    cardInput4.current.focus();
                  }
                }}
                maxLength={4}
                ref={cardInput3}
              />
              <Input
                style={inputStyle}
                onChange={(e) => {
                  setCardNumber4(e.target.value);
                  if (e.target.value.length === 4) {
                    validDateInput1.current.focus();
                  }
                }}
                maxLength={4}
                ref={cardInput4}
              />
            </div>
          </td>
        </tr>
        <tr className="table-two-col-container">
          <td style={{ textIndent: 10 }}>유효기간 (MM/YYYY)</td>
          <td style={{ textAlign: "left" }}>
            <Input
              style={inputStyle}
              placeholder="MM"
              onChange={(e) => {
                setMM(e.target.value);
                if (e.target.value.length === 2) {
                  validDateInput2.current.focus();
                }
              }}
              maxLength={2}
              ref={validDateInput1}
            />
            <Input
              style={inputStyle}
              placeholder="YYYY"
              onChange={(e) => {
                setYYYY(e.target.value);
                if (e.target.value.length === 4) {
                  pwdInput1.current.focus();
                }
              }}
              maxLength={4}
              ref={validDateInput2}
            />
            <Input.Password
              style={{ width: "15%", marginLeft: "20px", height: "73%" }}
              onChange={(e) => {
                setCreditcard_paswd(e.target.value);
                if (e.target.value.length === 2) {
                  if (changeCardMethod) {
                    businInput1.current.focus();
                  } else {
                    birthInput1.current.focus();
                  }
                }
              }}
              ref={pwdInput1}
            />
            <span style={{ fontSize: 12 }}>
              ** 비밀번호 앞 두자리 입력해주세요
            </span>
          </td>
        </tr>
        {changeCardMethod ? (
          <tr className="table-two-col-container">
            <td style={{ textIndent: 10 }}>사업자번호(10 자리)</td>
            <td style={{ textAlign: "left" }}>
              <Input
                style={inputStyle}
                ref={businInput1}
                maxLength={10}
                onChange={(e) => setBirth(e.target.value)}
              />
            </td>
          </tr>
        ) : (
          <tr className="table-two-col-container">
            <td style={{ textIndent: 10 }}>생년월일(6 자리)</td>
            <td style={{ textAlign: "left" }}>
              <Input
                style={inputStyle}
                onChange={(e) => setBirth(e.target.value)}
                ref={birthInput1}
                maxLength={6}
              />
            </td>
          </tr>
        )}
      </table>
    </div>
  );
};

export default PaymentForm;
