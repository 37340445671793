import React, { useState } from "react";
import "./insuranceProductList.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/";
import {
  changeTableDataList,
  changeSLProdNumber,
} from "../../redux/reducers/journeyRegisterReducer";
import { reqPost } from "../../libs/requests";
import api from "../../libs/api";
import Modal from "react-modal";
import Step1 from "../../components/insurproductinfo/Step1";
import Step2 from "../../components/insurproductinfo/Step2";
import Step3 from "../../components/insurproductinfo/Step3";
import { moneyComma } from "../../libs/libcollect";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "90%",
    zIndex: 100,
    padding: 0,
  },
};

interface Props {
  areaGubun: string;
  modalIsOpen: boolean;
  setModalIsOpen: any;
  setSl_iprd_reg_if: (value: string) => void;
  setTotal_prmim: (value: number) => void;
  setTotal_prmim_cost: (value: number) => void;
  insurCalcBtnClick: boolean;
}

const InsuranceProductList = ({
  areaGubun,
  modalIsOpen,
  setModalIsOpen,
  setSl_iprd_reg_if,
  setTotal_prmim,
  setTotal_prmim_cost,
  insurCalcBtnClick,
}: Props) => {
  const dispatch = useDispatch();
  const [insurModalIsOpen, setInsurModalIsOpen] = useState(false);
  const [sl_prod_number, setSl_prod_number] = useState<string>("");
  const [btnStep, setBtnStep] = useState<number>(1);

  const { insurProductList, nationCode } = useSelector(
    (state: RootState) => state.journeyRegister
  );

  const handlePurchase = (
    sl_iprd_reg_if_key: string,
    total_prmim: number,
    total_prmim_cost: number,
    insurer_list: any,
    sl_prod_number: string
  ) => {
    dispatch(
      changeSLProdNumber({
        sl_prod_number: sl_prod_number,
      })
    );
    if (areaGubun === "IP20201202035154rjQR") {
      const sendData = {
        country_code: nationCode,
        sl_iprd_reg_if: sl_iprd_reg_if_key,
      };

      reqPost(api.overseascheck, sendData, (result: any) => {
        if (result.data.resultinfo.result_div_cd === "OK") {
          setModalIsOpen(!modalIsOpen);
          setSl_iprd_reg_if(sl_iprd_reg_if_key);
          setTotal_prmim(total_prmim);
          setTotal_prmim_cost(total_prmim_cost);
          dispatch(
            changeTableDataList({
              tableDataList: insurer_list,
            })
          );
        } else {
          alert(result.data.resultinfo.result_msg);
        }
      });
    } else {
      setModalIsOpen(!modalIsOpen);
      setSl_iprd_reg_if(sl_iprd_reg_if_key);
      setTotal_prmim(total_prmim);
      setTotal_prmim_cost(total_prmim_cost);
      dispatch(
        changeTableDataList({
          tableDataList: insurer_list,
        })
      );
    }
  };
  const showInsurance = (sl_prod_number: string) => {
    setSl_prod_number(sl_prod_number);
    dispatch(
      changeSLProdNumber({
        sl_prod_number: sl_prod_number,
      })
    );
    setInsurModalIsOpen(true);
  };
  function closeModal() {
    setInsurModalIsOpen(false);
  }

  return (
    <div>
      <div className="InsuranceProductList">
        {!insurCalcBtnClick ? (
          insurProductList.length === 0 &&
          insurCalcBtnClick && <h1>가입 조건에 해당하는 상품이 없습니다.</h1>
        ) : (
          <table className="insurPeople-form-table">
            <thead>
              <tr>
                <th>순서</th>
                <th>보험사명</th>
                <th>보험상품명</th>
                <th>보험플랜</th>
                <th>보장내용</th>
                <th>상품설명</th>
                <th>총 보험료</th>
                <th>구매하기</th>
              </tr>
            </thead>

            <tbody>
              {insurProductList.map((item: any, index: number) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.insurcom_name}</td>
                  <td>{item.prod_name}</td>
                  <td style={{ textAlign: "center" }}>{item.plangrd_name}</td>
                  <td>
                    <button
                      className="insurance-btn"
                      onClick={() => {
                        setBtnStep(2);
                        showInsurance(item.sl_prod_number);
                      }}
                    >
                      보장내용 보기
                    </button>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <button
                      className="insurance-btn"
                      onClick={() => {
                        setBtnStep(1);
                        showInsurance(item.sl_prod_number);
                      }}
                    >
                      상품보기
                    </button>
                  </td>
                  <td>{moneyComma(item.total_prmim_cost)}</td>
                  <td style={{ textAlign: "center" }}>
                    <button
                      className="insurance-purchase-btn"
                      onClick={() =>
                        handlePurchase(
                          item.sl_iprd_reg_if,
                          item.total_prmim,
                          item.total_prmim_cost,
                          item.insurer_list,
                          item.sl_prod_number
                        )
                      }
                    >
                      구매하기
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <Modal
        isOpen={insurModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div>
          <div className="modal-exit">
            <button onClick={closeModal}>닫기</button>
          </div>
          <div className="insurance-content-container">
            <button
              style={{
                backgroundColor: btnStep === 1 ? "#444054" : "#F7F7F7",
                color: btnStep === 1 ? "#fff" : "#6c757d",
              }}
              className="insurance-content-container-btn"
              onClick={() => setBtnStep(1)}
            >
              상품설명
            </button>
            <button
              style={{
                backgroundColor: btnStep === 2 ? "#444054" : "#F7F7F7",
                color: btnStep === 2 ? "#fff" : "#6c757d",
              }}
              className="insurance-content-container-btn"
              onClick={() => setBtnStep(2)}
            >
              보장내용
            </button>
            <button
              style={{
                backgroundColor: btnStep === 3 ? "#444054" : "#F7F7F7",
                color: btnStep === 3 ? "#fff" : "#6c757d",
              }}
              className="insurance-content-container-btn"
              onClick={() => setBtnStep(3)}
            >
              알아두실사항
            </button>
          </div>
          <div className="insurance-content-container">
            {btnStep === 1 && <Step1 sl_prod_number={sl_prod_number} />}
            {btnStep === 2 && <Step2 sl_prod_number={sl_prod_number} />}
            {btnStep === 3 && <Step3 />}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InsuranceProductList;
