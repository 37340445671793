import axios from "axios";
import { apiUrl } from "./consts";
import consts from "./consts";
import { itemKey, getItem } from "../libs/preference";
export const reqPostAuth = (url: string, body: any, callback: any) => {
  //   body = JSON.stringify(body);
  const headers = {
    "Content-type": "application/json",
    v: "1.0.0",
    "Access-Orign": consts.originUrl,
  };

  axios
    .post(`${url}`, body, { headers })
    .then((response) => {
      callback(response);
    })
    .catch((response) => {
      callback(response);
    });
};
export const reqPost = (url: string, body: any, callback: any) => {
  let token = getItem(itemKey.token);
  const headers = {
    "Content-type": "application/json",
    v: "1.0.0",
    a: token ? token : "",
    "Access-Orign": consts.originUrl,
  };
  axios
    .post(`${apiUrl}${url}`, body, { headers })
    .then((response) => {
      callback(response);
    })
    .catch((response) => {
      callback(response);
    });
};
